import React, {useCallback, useEffect} from 'react';
import ContainerContent from "../../components/ui/ContainerContent";
import PageTitle from "../../components/PageTitle/PageTitle";
import {useDispatch, useSelector} from "react-redux";
import {
    getGeneralGoodsService,
    getGeneralGoodsServiceType,
    getReadinessReport
} from "../../store/selectors/salary_project_selector";
import {
    getPartsInformationForProductionMarksByOrder,
    readinessReportGetOrdersByFilterThunk,
} from "../../store/thunks/salary_project/ready_mark_thunk";
import {
    getGeneralGoodsServiceThunk,
    getGeneralGoodsServiceTypeThunk
} from "../../store/thunks/salary_project/general_thunk";
import "./main.css"
import LeftToMake from "./LeftToMake";
import {setReportsDataSaleThunk} from "../../store/reducers/MainReducer";
import {transformTimeAndDate} from "../../printHtml/helpers";
import {LS_LANG} from "../../utils/constants";


//** страница отчет готовности*/
const SalaryProjectReadinessReport = ({getT}) => {
    let lang = localStorage.getItem(LS_LANG);
    const dispatch = useDispatch();
    let sale = useSelector(state => state.main.reports.sale)
    // let currency = useSelector(state => state.main.reports.currency)

    const readiness_report_state = useSelector(getReadinessReport);
    const service_type_state = useSelector(getGeneralGoodsServiceType)
    const service_state = useSelector(getGeneralGoodsService)

    useEffect(() => {
        // Get the current date
        var currentDate = new Date();
        // Get the next day
        var nextDay = new Date();
        nextDay.setDate(currentDate.getDate() + 1);
        let date_from = transformTimeAndDate(currentDate)
        let date_to = transformTimeAndDate(nextDay)
        dispatch(setReportsDataSaleThunk('leftMake', {
            date_from: date_from?.date,
            date_to: date_to?.date,
            currency: 2,
            manager: ""
        } ))
        // dispatch(readinessReportGetOrdersByFilterThunk(readiness_report_state.filter))
        // dispatch(getGeneralGoodsServiceTypeThunk());
        // dispatch(getGeneralGoodsServiceThunk())
    }, [dispatch]);





    return (
        <ContainerContent>
            <PageTitle title={"readiness_report"}/>
            <div className="container-fluid ">
                <div className="row">

                    <div className="col-12 col-xl-12">
                        <LeftToMake getT={getT} lang={lang} sale={sale} order_detailing={readiness_report_state?.order_detailing}/>
                        {/*<OrderFilter getT={getT}*/}
                        {/*             goods_service_type_data={service_type_state?.data || []}*/}
                        {/*             goods_service_data={service_state?.data || []}*/}
                        {/*             filter={readiness_report_state?.filter} handlerGetOrders={handlerGetOrders}/>*/}
                        {/*<Orders getT={getT} handlerGetPartForOrderProduction={handlerGetPartForOrderProduction}*/}
                        {/*        orders={readiness_report_state?.orders} handlerGetOrders={handlerGetOrders}*/}
                        {/*/>*/}
                    </div>
                </div>
            </div>
            {/*<SalaryReadeReadinessPreportPartForOrderForProductionModal getT={getT} key={'sal_proj_part_of_order'}/>*/}

        </ContainerContent>
    );
};

export default SalaryProjectReadinessReport;