import React from 'react';
import {IconDelete} from "../../../Icons/IconDelete";

const OrderServicesExistingTable = ({services, onDeleteService, getT, isEdit}) => {

    if (!Array.isArray(services) || !services.length) {
        return <tr> <td colSpan={"7"}>{getT("Данные отсутствуют")}</td></tr>;
    }
    return (
        <>

            {services?.map(e => {
                let cost = (Number(e.count) * Number(e.price)).toFixed(2)
                return <tr>
                    <td colSpan={3}
                        data-label={getT("Название")}>[{e.goods_service_for_order_by_firm}] {e.name}</td>
                    <td data-label={getT("Количество")}>{e.count}</td>
                    <td data-label={getT("Цена")}>{e.price} {e.currency_name}</td>
                    <td data-label={getT("Сумма")}>{cost} {e.currency_name}</td>
                    <td>
                        {isEdit && <span className={"cursor-pointer"} onClick={() => {
                            onDeleteService(e.goods_service_for_order_by_firm_orders_connection_id)
                        }}>
                            <IconDelete/>
                            </span>
                        }
                    </td>

                </tr>
            })}


        </>
    );
};

export default OrderServicesExistingTable;