export const getDefaultPlane = (state) => state?.salary_project?.default_plane;
export const getDefaultPlaneCreateModal = (state) => state?.salary_project?.default_plane_create_modal;

export const getSalaryRates = (state) => state?.salary_project?.rates;
export const getSalaryRatesCreateModal = (state) => state?.salary_project?.rates_modal;

export const getGeneralCurrencies = (state) => state?.salary_project?.general_currencies;
export const getGeneralGoodsService = (state) => state?.salary_project?.general_goods_service;
export const getGeneralOrdersStatuses = (state) => state?.salary_project?.general_orders_statuses;
export const getGeneralGoodsServiceType = (state) => state?.salary_project?.general_goods_service_type;

export const getShift = (state) => state?.salary_project?.shift;
export const getShiftCreateModal = (state) => state?.salary_project?.shift_create_modal;
export const getShiftUpdateModal = (state) => state?.salary_project?.shift_update_modal;
export const getShiftFilter = (state) => state?.salary_project?.shift_filter;

export const getShiftOpen = (state) => state?.salary_project?.shift_open;
export const getShiftPlane = (state) => state?.salary_project?.shift_plane;

export const getShiftPlaneCreateModal = (state) => state?.salary_project?.shift_plane_create_modal;
export const getShiftPlaneUpdateModal = (state) => state?.salary_project?.shift_plane_update_modal;

export const getShiftWorkerGroupModal = (state) => state?.salary_project?.shift_worker_group_modal;
export const getEquipmentWorkerGroup = (state) => state?.salary_project?.equipment_worker_group;

export const getReadyMark = state => state?.salary_project?.ready_mark;
export const getSearchOrders = state => state?.salary_project?.search_orders;
export const getReadyMarkGoodsServiceModal = state => state?.salary_project?.ready_mark_goods_service_modal;
export const getReadyMarkPartForOrderModal = state => state?.salary_project?.ready_mark_part_form_order_modal;
export const getReadyMarkPackingPartForOrderModal = state => state?.salary_project?.ready_mark_packing_part_form_order_modal;

export const getUsersList = state => state?.salary_project?.users;

export const getReadinessReport= state => state?.salary_project?.readiness_report;

export const getAdditionalDocumentReadyMarkByShiftOpen= state => state?.salary_project?.additional_document_by_shift_open;
export const getAdditionalDocumentBonusesFinesCreateModal= state => state?.salary_project?.additional_document_bonuses_fines_create_modal;
export const getAdditionalDocumentBonusFineUpdateModal= state => state?.salary_project?.additional_document_bonus_fine_update_modal;

export const getScannerReadyMark = state => state?.salary_project?.scanner_read_mark;
export const getScannerReadyMarkInformation = state => state?.salary_project?.scanner_read_mark?.scanner_information ?? null;

export const getSalaryReport = state => state?.salary_project?.salary_report;