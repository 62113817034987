import {ReportPazHtml} from "../../printHtml/reportPazHtml";
import {reportPazHtml} from "../../printHtml";
import {toggleLoaderStatusAC} from "../reducers/MainReducer";
import {LS_LANG} from "../../utils/constants";
import {dispatcherErrorThunk} from "./common_thunk";

export const printHtmlReportPazThunk = (order) => async (dispatch, getState) => {
    try {
        console.log(order, 'prderfsdfg')
        dispatch(toggleLoaderStatusAC(true));
        let parts = order.part;
        let materials = order.material;
        let group_direction = parts?.reduce((acc, part) => {
            let paz = part.operations.paz;
            let pazOperations = Array.isArray(paz) ? paz : [];
            let materialId = Number(part.material);
            let material = materials.find(m => Number(m.goods_id) === materialId);
            let lang = localStorage.getItem(LS_LANG);

            let partForPaz = {id: part.id, name: part.name, x: part.x1, y: part.y1, z: part.z};
            let materialForPaz = {
                id: materialId,
                name: material?.translate?.[lang] ?? material?.name,
                x: material.x,
                y: material.y,
                z: material.z
            };
            pazOperations.forEach(paz => {
                let pazInfo = {side: paz.side, w_paz: paz.w_paz, z: paz.z, d1: paz.d1, side_from: paz.side_from};
                let keyPazWidthDepth = `paz_${paz.w_paz}_${paz.z}`;
                let keyPazSideD1 = `side_${paz.side_from}_${paz.d1}`;
                let keyMaterialId = `material_${materialId}`
                let accClone = {...acc ?? {}};
                if (!accClone.hasOwnProperty(keyPazWidthDepth)) {
                    acc = {
                        ...accClone,
                        [keyPazWidthDepth]: {
                            pazInfo: {w_paz: paz.w_paz, z: paz.z},
                            [keyMaterialId]: {
                                material: materialForPaz,
                                [keyPazSideD1]: {
                                    info: pazInfo,
                                    parts: [partForPaz]
                                }
                            }
                        }
                    }
                } else {
                    let pazSideD1Parts = [...accClone?.[keyPazWidthDepth]?.[keyMaterialId]?.[keyPazSideD1]?.parts ?? []]
                    acc = {
                        ...accClone,
                        [keyPazWidthDepth]: {
                            ...accClone?.[keyPazWidthDepth],
                            [keyMaterialId]: {
                                ...accClone?.[keyPazWidthDepth]?.[keyMaterialId],
                                material: materialForPaz,
                                [keyPazSideD1]: {
                                    info: pazInfo,
                                    parts: [...pazSideD1Parts, partForPaz]
                                }
                            }
                        }
                    }
                }
            })
            return acc;

        }, null)

        let content = new ReportPazHtml().generateContentHtml({group_direction: group_direction, order})
        reportPazHtml.openWindowPrint({content: content, order: order})
        dispatch(toggleLoaderStatusAC(false));


    } catch (e) {
        console.log(e.stack)
        dispatch(dispatcherErrorThunk(e))

    }
}