import React from "react";

const SelectPhone = ({rusHide, value, setValue, style={}}) => {
    return (
        <>
            <select
                style={style}
                className="form-control"
                value={value}
                defaultValue={value}
                onChange={(event) => {
                    // const code = listCountryCode.getCode(regionsList, event.target.value);
                    setValue(event.target.value)
                }}
            >
                {/*{listCountryCode?.excludeRegions(regionsList, 'ru', prodRusHide)}*/}
                {/*{regionsList?.map(region=> <option value={region?.value}>{region?.value}</option>)}*/}
                <option value="+43">(AT) +43</option>
                <option value={"+359"}>(BG) +359</option>
                <option value="+49"> (DE) +49</option>
                <option value="+372">(ET) +372</option>
                <option value={"+972"}>(HE) +972</option>
                <option value={"+36"}>(HU) +36</option>
                <option value="+370">(LT) +370</option>
                <option value="+371">(LV) +371</option>
                <option value={"+373"}>(MD) +373</option>
                <option value={"+48"}>(PL) +48</option>
                <option value={"+40"}>(RO) +40</option>
                {rusHide ? <option value={"+7 "}>(RU) +7</option> : null}
                <option value="+421">(SK) +421</option>
                <option value={"+38"}>(UA) +38</option>


            </select>
        </>
    );
};

export default SelectPhone;