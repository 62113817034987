import React from 'react';
import Button from "../../../components/ui/Button";

const ScannerInformationByAction = ({getT, handlerSheetForm, data_part_by_link = null}) => {

    if (!data_part_by_link) {
        return null;
    }
    return (
        <div className={"card "} style={{maxWidth: "860px"}}>
            <div className="card-header">
                <div className="card-title">
                    {getT("Деталь")} № {data_part_by_link?.part} {" "}
                    <br/>
                    {getT("Уникальный номер")} № {data_part_by_link?.part_unique_number}
                </div>
                {data_part_by_link.order_id &&
                    <div className={"d-flex justify-content-end flex-wrap"} style={{gap: '10px'}}>
                        <Button className={"btn-info fs-14"}
                                onClick={handlerSheetForm(data_part_by_link.order_id, false, true)}>{getT("Не упакованные детали")}</Button>
                        <Button className={"btn-primary fs-14"}
                                onClick={handlerSheetForm(data_part_by_link.order_id, true, false)}>{getT("Спецификация")}</Button>

                    </div>
                }
            </div>
            <div className="card-body">
                <div className="d-flex justify-content-center">
                    <iframe allow="fullscreen"  style={{width: "100%", height: "760px"}} src={data_part_by_link?.link} />
                    {/*<img src={data_part_by_link?.link} alt="" style={{maxWidth: "100%"}}/>*/}
                </div>

            </div>

        </div>
    );
};

export default ScannerInformationByAction;