import React from 'react';
import {ACTION_TYPE_ORDER_GROUP_TAB} from "../ProjectTask/constans";

const Tab = ({onChangeTab, isActiveTabType, getT}) => {
    return (
        <div className={'tab bloc-tabs nav nav-tabs justify-content-center'}>
                <button
                    className={isActiveTabType === ACTION_TYPE_ORDER_GROUP_TAB.task ? "tabs active-tabs btn nav-link active" : "btn tabs nav-link"}
                    onClick={() => onChangeTab(ACTION_TYPE_ORDER_GROUP_TAB.task)}
                >
                    {getT("Созданные производственные задания")}
                </button>
                <button
                    className={isActiveTabType === ACTION_TYPE_ORDER_GROUP_TAB.material ? "tabs active-tabs btn nav-link active" : "btn tabs nav-link"}
                    onClick={() => onChangeTab(ACTION_TYPE_ORDER_GROUP_TAB.material)}
                >
                    {getT("Создание по материалам")}
                </button>
        </div>
    );
};

export default Tab;