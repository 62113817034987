import React from "react";
import {connect} from "react-redux";
import {
    changeOrdersFilterFormValueAC,
    createNewOrderThunk,
    getOrdersFilteredDataThunk,
    initialOrderSearch,
    setClientsDataThunk,
    setMaterialsThunk,
    setOrdersDataAC,
    setOrdersDataThunk,
    setServiceThunk,
    toggleOrderChangeStatusAC,
    updateOrderDataThunk,
    updateSettingsThunk
} from "../../store/reducers/MainReducer";
import OrderSearch from "./OrderSearch";

class OrderSearchContainer extends React.Component {
    constructor() {
        super();
        this.state = {
            isLoader: true
        }
    }

    componentDidMount() {
        this.props.initialOrderSearch(() => {
          setTimeout(()=>{
              this.setState({isLoader: false})
          }, 1000)
        })
    }

    componentWillUnmount() {
        // this.props.checkAuntificationUser();
        this.props.setNullOrders([])
    }

    render() {
        // if (true) return <Loader/>
        return (
            <>
                <OrderSearch
                    orders={
                        this.props.archive
                            ? this.props.orders_archive
                            : this.props.orders_actual
                    }
                    title={!this.props.archive ? "orders_actual" : "orders_archive"}
                    setOrders={this.props.setOrders}
                    changeFilterValue={this.props.changeFilterValue}
                    toggleChangeStatus={this.props.toggleChangeStatus}
                    updateOrder={this.props.updateOrder}
                    form={this.props.filterForm}
                    status={this.props.orderStatus}
                    clients={this.props.clients}
                    changedStatus={this.props.changedStatus}
                    createOrder={this.props.createOrder}
                    userRoles={this.props.user.role}
                    managers={this.props.managers}
                    materials={this.props.materials}
                    services={this.props.services}
                    lang={this.props.lang}
                />
            </>
        );
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        setOrders: (filters = null) => dispatch(setOrdersDataThunk(filters)),
        changeFilterValue: (input, value, filters) =>
            dispatch(getOrdersFilteredDataThunk(input, value, filters)),
        setNullFilter: (input, value) =>
            dispatch(changeOrdersFilterFormValueAC(input, value)),
        setClients: (withLoader) => dispatch(setClientsDataThunk(withLoader)),
        setMaterials: (withLoader) => dispatch(setMaterialsThunk(withLoader)),
        setServices: (withLoader) => dispatch(setServiceThunk(withLoader)),
        toggleChangeStatus: (id) => dispatch(toggleOrderChangeStatusAC(id)),
        // setManagers: () => dispatch(getManagersDataThunk()),
        setManagers: () => dispatch(updateSettingsThunk()),
        updateOrder: (order_id, data, status) =>
            dispatch(updateOrderDataThunk(order_id, data, status)),
        createOrder: (client_id) => dispatch(createNewOrderThunk(client_id)),
        setNullOrders: (orders) => dispatch(setOrdersDataAC(orders)),
        initialOrderSearch: (cb) => dispatch(initialOrderSearch(cb)),
    };
};

let mapStateToProps = (state) => {
    return {
        orders_actual: state.main.orders.actual,
        orders_archive: state.main.orders.archive,
        filterForm: state.main.ordersForm,
        orderStatus: state.main.orderStatus,
        managers: state.main.managers,
        clients: state.main.clients,
        changedStatus: state.main.ordersChangeStatus,
        user: state.auth.user,
        materials: state.main.ordersForm.materials,
        services: state.main.ordersForm.services,
        lang: state.translation.local,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderSearchContainer);
