import {AdvancedGoodsServiceAPI} from "../../api/Order/AdvancedGoodsServiceAPI";
import {dispatcherErrorThunk} from "./common_thunk";
import {toggleLoaderStatusAC} from "../reducers/MainReducer";
import {
    setAdvancedGoodsServiceForOrder,
    setExistingGoodsServiceForOrderModalAC
} from "../actions/AdvancedGoodsServiceActions";

async function dispatchedGetAllAdvancedGoodsServiceForOrder(orderId, dispatch) {

    let response = await AdvancedGoodsServiceAPI.getAllByOrderId(orderId);
    if (!!response.error) {
        dispatch(dispatcherErrorThunk(response))
        return;
    }
    console.log(response.data)
    if (response?.data) {
        dispatch(setAdvancedGoodsServiceForOrder(response?.data))
    }
    return response


}

export const getAllAdvancedGoodsServiceByOrderIdThunk = (orderId) => async (dispatch) => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        await dispatchedGetAllAdvancedGoodsServiceForOrder(orderId, dispatch);
        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}

export const deleteAdvancedGoodsServiceByIdForOrderIdThunk = (goodsId, orderId) => async (dispatch) => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        const response = await AdvancedGoodsServiceAPI.deleteGoodsServiceById(goodsId);
        if (!!response?.error) {
            dispatch(dispatcherErrorThunk(response));
            return;
        }
        await dispatchedGetAllAdvancedGoodsServiceForOrder(orderId, dispatch);
        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}

export const setAdvancedGoodsServiceForOrderIdThunk = (form_api, orderId) => async (dispatch) => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        const response = await AdvancedGoodsServiceAPI.setGoodsService(form_api);
        if (!!response?.error) {
            dispatch(dispatcherErrorThunk(response));
            return;
        }
        await dispatchedGetAllAdvancedGoodsServiceForOrder(orderId, dispatch);
        dispatch(setExistingGoodsServiceForOrderModalAC({isOpen: false, orderId: null}));

        dispatch(toggleLoaderStatusAC(false));
    } catch
        (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}