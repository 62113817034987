import material_table from "./material_table";
import band_table from "./band_table";
import parts_table from "./parts_table";
import getT from "../getT";

export default function ({order,  order_reset_data}) {
    let material = Array.isArray(order?.material) ? order?.material : []
    let band = Array.isArray(order?.band) ? order?.band : [];
    let count_part = Array.isArray(order?.count_part) ? order?.count_part : [];
    let part = Array.isArray(order?.part) ? order?.part : [];
    let part_entry_info = Array.isArray(order_reset_data?.parts) ? order_reset_data?.parts : [];
    console.log(order)
    let material_filtered = material?.filter(material => part_entry_info?.filter(part => Number(part.info.material) === Number(material.id))?.length)
    let render = material_filtered?.map((material, index) => {
        let parts_include = part_entry_info
            ?.filter(part => Number(part.info.material) === Number(material.id))
            ?.map(e => {
                let part = e.info;
                let part_count = count_part.find(e => Number(e.part_id) === Number(part.id))
                return {...part, count: part_count?.count, link: e.link,  qrCode: e.qrCode,}
            })
        let bands_include = band
            ?.filter(band => parts_include.some(part => Object.entries(part.edge).some(([_, values]) => Number(band.id) === Number(values))))
            ?.map(band => {
                return {
                    ...band, band_count: 0
                }
            })

        let material_list =  [material]

        if (!material_list) return `
        <p>${getT("Не удалось создать спецификацию")}</p>
        `
        return `
        <div style="font-size: 0; ">
            ${material_table({material, index, material_list})}
            ${bands_include?.length ? band_table({bands: bands_include}) : ''}
            ${parts_include?.length ? parts_table({bands: bands_include, parts: parts_include}) : ''}
         </div>   
   
        `
    })?.join('');

    return render
}