export const FETCH_ALL_ADVANCED_GOODS_SERVICES_FOR_ORDER = 'ADVANCED_GOODS_SERVICES/FETCH_ALL_FOR_ORDER';
export const SET_GOODS_SERVICES_FOR_ORDER_MODAL = 'ADVANCED_GOODS_SERVICES/SET_MODAL_FOR_ORDER';


/**
 * @function setAdvancedGoodsServiceForOrder
 * @description Диспатчит список всех услуг для конкретного заказа по его id
 * @param {object || null} payload - услуги для конкретного idOrder
 *      all: array, // все доступные услуги
 *      existing: array // существующие услуги для заказа
 * @returns {object} Экшен для получения всех услуг для заказа
 */

export const setAdvancedGoodsServiceForOrder = (payload = null) => {
    return {
        type: FETCH_ALL_ADVANCED_GOODS_SERVICES_FOR_ORDER,
        payload: payload
    }
}

/**
 * @function setAdvancedGoodsServiceForOrderModalAC
 * @description Диспатчит состояние модального окна для добавления услуг к заказу
 * @param {object} payload - объект с состоянием модального окна
 *      isOpen: boolean, // открыто ли окно
 *      orderId: number|null // id заказа
 *      part: array //детали
 *      products:array // продукты
 * @returns {object} Экшен для управления состоянием модального окна
 */

export const setExistingGoodsServiceForOrderModalAC = ({
                                                           isOpen = false,
                                                           orderId = null,
                                                           part = null,
                                                           products = null
                                                       }) => {
    return {
        type: SET_GOODS_SERVICES_FOR_ORDER_MODAL,
        payload: {isOpen: isOpen, orderId: orderId, part: part, products: products}
    }
}