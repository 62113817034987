import React, {useMemo} from 'react';
import {useDispatch} from "react-redux";
import Select from "react-select";
import {scannerReadMarkToggleAllAC} from "../../../store/actions/SalaryProject";

const ID_SERV_PACKAGE = 17;
const ScannerFilterForm = ({getT, initial_form, services = [], equipment_worker_group_by_shift}) => {
    const dispatch = useDispatch();
    const handlerChangeRadio = (key) => (e) => {
        let value = Number(e.target.value);
        let type_order_or_part = !!Number(initial_form?.type_order_or_part);
        let filter_data = {
            ...initial_form, [key]: value,
        }
        if (key === 'type_add_or_delete_mark' && !!value && !type_order_or_part) {
            filter_data = {
                ...filter_data, type_package_or_service: 1
            }
        }

        if (key === 'type_package_or_service') {
            filter_data = {
                ...filter_data, service_type: []
            }
        }
        dispatch(scannerReadMarkToggleAllAC({
            filter: filter_data,
            start_scanner: false,
            scanner_information: null,
        }))
    }
    const handlerChangeSelect = (key, value) => {

        dispatch(scannerReadMarkToggleAllAC({
            filter: {...initial_form, [key]: value},
            start_scanner: false
        }))
    }
    let type_order_or_part = !!Number(initial_form?.type_order_or_part);
    let type_add_or_delete_mark = !!Number(initial_form?.type_add_or_delete_mark);
    let type_need_confirm = !!Number(initial_form?.type_need_confirm);
    let type_package_or_service = !!Number(initial_form?.type_package_or_service);
    // let type_package_or_service = !!Number(initial_form?.type_package_or_service);

    const goods_service_type = useMemo(() => {
        if (!services || !equipment_worker_group_by_shift) return [];
        let availible_goods_type = equipment_worker_group_by_shift?.map(item => item?.sevice_type_availible)?.flat()
        let uniq_goods_type_ids = [...new Set(availible_goods_type)]?.map(item => Number(item?.service_type));
        return services?.filter(item => uniq_goods_type_ids.includes(Number(item?.type)));
    }, [services, equipment_worker_group_by_shift]);

    const service_type_values_select = useMemo(() => {
        let service = goods_service_type;
        if (!service) return []
        return initial_form?.service_type?.map(id => {
            let find_serv = services?.find(e => Number(e.goods_service_id) === Number(id));
            if (find_serv) {
                return {
                    value: find_serv.goods_service_id,
                    label: find_serv?.name
                }
            }
        })?.filter(i => i?.value && i?.label)
    }, [goods_service_type, initial_form?.service_type]);

    return (
        <div className="card my-3">
            <div className="col-12">
                <div className={"row"}>
                    <div className="col-6 col-sm-6 col-md-4 col-lg-2">
                        <div className="form-group">
                            <label>{getT("Сканировать")}</label>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="type_order_or_part"
                                       id="type_order_or_part_order" value="0" checked={!type_order_or_part}
                                       onChange={handlerChangeRadio('type_order_or_part')}/>
                                <label className="form-check-label" htmlFor="type_order_or_part_order">
                                    {getT("Заказ")}
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="type_order_or_part"
                                       onChange={handlerChangeRadio('type_order_or_part')}
                                       id="type_order_or_part_part" value="1" checked={type_order_or_part}/>
                                <label className="form-check-label" htmlFor="type_order_or_part_part">
                                    {getT("Деталь")}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-4 col-lg-2">
                        <div className="form-group">
                            <label>  {getT("Действие")}</label>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="type_add_or_delete_mark"
                                       onChange={handlerChangeRadio('type_add_or_delete_mark')}
                                       id="type_add_or_delete_mark_delete" value="0"
                                       checked={!type_add_or_delete_mark}/>
                                <label className="form-check-label" htmlFor="type_add_or_delete_mark_delete">
                                    {getT("Снять отметку")}
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="type_add_or_delete_mark"
                                       onChange={handlerChangeRadio('type_add_or_delete_mark')}
                                       id="type_add_or_delete_mark_add" value="1" checked={type_add_or_delete_mark}/>
                                <label className="form-check-label" htmlFor="type_add_or_delete_mark_add">
                                    {getT("Установить отметку")}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-4 col-lg-2">
                        <div className="form-group">
                            <label>  {getT("Подтверждать действие")}</label>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="type_need_confirm"
                                       onChange={handlerChangeRadio('type_need_confirm')}
                                       id="type_need_confirm_no" value="0" checked={!type_need_confirm}/>
                                <label className="form-check-label" htmlFor="type_need_confirm_no">
                                    {getT("Нет")}
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="type_need_confirm"
                                       onChange={handlerChangeRadio('type_need_confirm')}
                                       id="type_need_confirm_yes" value="1" checked={type_need_confirm}/>
                                <label className="form-check-label" htmlFor="type_need_confirm_yes">
                                    {getT("Да")}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-4 col-lg-2">
                        <div className="form-group">
                            <label>  {getT("Отметить")}</label>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="type_package_or_service"
                                       onChange={handlerChangeRadio('type_package_or_service')}
                                       id="type_package_or_service_package" value="0"
                                       checked={!type_package_or_service}
                                       disabled={type_add_or_delete_mark && !type_order_or_part}/>
                                <label className="form-check-label" htmlFor="type_package_or_service_package">
                                    {getT("Упаковку")}
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="type_package_or_service"
                                       onChange={handlerChangeRadio('type_package_or_service')}
                                       id="type_package_or_service_service" value="1"
                                       checked={type_package_or_service}/>
                                <label className="form-check-label" htmlFor="type_package_or_service_service">
                                    {getT("Услуги")}
                                </label>
                            </div>
                        </div>
                    </div>
                    {type_package_or_service &&
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                            <div className="form-group">
                                <label>{getT("Услуги")}</label>
                                <Select
                                    placeholder={getT("Поиск")}
                                    defaultValue={service_type_values_select}
                                    isMulti={true}
                                    options={goods_service_type?.map(service => {
                                        return {
                                            value: service.goods_service_id,
                                            label: service?.name
                                        }
                                    })}
                                    onChange={(selectOptions) => {
                                        let values = selectOptions?.map(item => item?.value);

                                        handlerChangeSelect('service_type', values)
                                    }}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>

    );
};

export default ScannerFilterForm;