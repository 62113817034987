import React, {useMemo, useState} from "react";
import "./ResponsiveTable.css";
import PageTitle from "../PageTitle/PageTitle";
import {Link} from "react-router-dom";
import Select from "react-select";
import {v4 as uuid} from "uuid";
import {DateRange} from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import * as rdrLocales from "react-date-range/dist/locale";
import {useDispatch, useSelector} from "react-redux";
import {
    cacheResetThunc,
    changeContragentDataThunc, ConfirmCommonModalAC,
    onChangeOrderPaymountStatus,
    onChangeStatusAutomaticallyTaskThunk,
    onDeleteOrderProject,
    updateOrderProductionDateThunk
} from "../../store/reducers/MainReducer";
import ApiService from "../../api/api";
import {withLang} from "../../hoc/withLang";
import IconPaid from "../../Icons/IconPaid";
import AccordionMaterialService from "../AccordionMaterialService";
import {IconDelete} from "../../Icons/IconDelete";
import {CodeOneC} from "./CodeOneC";
import {gaEvents} from "../../gaEvents";
import FilterOrders from "./Form/FilterOrders";
import {useDetectClickOutside} from "react-detect-click-outside";
import {getVatAddPriceIfMoreThan} from "../../store/selectors/main_selector";

const options = [
    {value: "chocolate", label: "Chocolate"},
    {value: "strawberry", label: "Strawberry"},
    {value: "vanilla", label: "Vanilla"}
];
const AVAILABLE_RESET_CACHE = [2, 3, 4, 5, 6];
const Orders = (props) => {
    const lang = useSelector(state => state.translation.local);

    const [visibleClearDate, setVisibleClearDate] = useState(false);
    const [visibleClearDate_production, setVisibleClearDate_production] =
        useState(false);
    const [openDatePicker, setOpenDatePicker] = useState(false);
    const [openDate_productionPicker, setOpenDate_productionPicker] =
        useState(false);
    const toggleOpenDatePicker = () => {
        setOpenDatePicker(!openDatePicker);
    };

    const toggleOpenDate_productionPicker = () => {
        setOpenDate_productionPicker(!openDate_productionPicker);
    };

    const [date, setDate] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection"
        }
    ]);

    const [date_production, setDate_production] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection"
        }
    ]);

    const saveData = () => {

        props.changeFilterValue(
            "date",
            {
                from: date[0].startDate.toISOString().split("T")[0],
                to: date[0].endDate.toISOString().split("T")[0]
            },
            props.form
        );
        setVisibleClearDate(true);
        setOpenDatePicker(false);
    };

    const clearData = () => {
        props.changeFilterValue(
            "date",
            {
                from: "",
                to: ""
            },
            props.form
        );
        setVisibleClearDate(false);
    };
    const onResetForms = () => {
        selectInputRef.current.select.clearValue();
        props.clearData();
    }

    const saveData_production = () => {
        props.changeFilterValue(
            "date_production",
            {
                from: date_production[0].startDate.toISOString().split("T")[0],
                to: date_production[0].endDate.toISOString().split("T")[0]
            },
            props.form
        );
        setOpenDate_productionPicker(false);
        setVisibleClearDate_production(true);
    };

    const clearData_production = () => {
        props.changeFilterValue(
            "date_production",
            {
                from: "",
                to: ""
            },
            props.form
        );
        setVisibleClearDate_production(false);
    };
    const selectInputRef = React.useRef();

    const updateStatusForOrder = (order_id, data) => {
        let currentStatusFilter = props.form.status;
        props.updateOrder(order_id, data, currentStatusFilter);
    }
    const renderOrders = () => {
        if (props.orders.length > 0) {
            return props.orders
                .filter((e) => {
                    if (Number(e.status) === 8 || e.status === "отменен") {
                        return false;
                    } else {
                        return true;
                    }
                })
                .map((e) => {
                    return (
                        <OrderItem
                            clients={props.clients}
                            manager={props.manager}
                            order={e}
                            toggleChangeStatus={props.toggleChangeStatus}
                            updateOrder={updateStatusForOrder}
                            status={props.status}
                            changedStatus={props.changedStatus}
                            withStatus={true}
                            withClient={true}
                            roles={props.userRoles}
                            orders_check_enable={props.orders_check_enable}
                            settings_projects={props.settings_projects}
                            onResetForms={onResetForms}
                            getT={props.getT}
                            isFeatureMaterialServiceAccordion={true}
                        />
                    );
                });
        } else {
            return (
                <tr>
                    <td colSpan={9}>
                        <p className={"text-center"}>{props.getT("Заказы еще не созданы")}</p>
                    </td>
                </tr>
            );
        }
    };
    const selectClientsPhones = () => {
        let options = props.clients.map((e) => {
            return {
                label: e.name + " " + "(" + e.phone + ")",
                value: e.id,
                isOptionSelected: Number(e.id) === Number(props.form.client) ? true : false
            };
        })

        return options;
    }

    return (
        <div className="content-wrapper">
            <PageTitle title={props.title}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="card">
                        <FilterOrders form={props.form} getT={props.getT} changeFilterValue={props.changeFilterValue}>
                            <>
                                {/* Формат даты по промежутку */}
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        {visibleClearDate && (
                                            <i
                                                style={{marginRight: "10px"}}
                                                onClick={clearData}
                                                class="fas fa-ban "
                                            >
                                                {props.getT("Сбросить")}
                                            </i>
                                        )}

                                        <i
                                            style={{
                                                fontSize: "24px",
                                                color: "red",
                                                cursor: "pointer"
                                            }}
                                            class="fa fa-refresh fa-lg"
                                        ></i>
                                        <label>{props.getT("Дата")}:</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            value={`${props.form?.date?.from || ""} ... ${
                                                props.form?.date?.to || ""
                                            }`}
                                            onClick={toggleOpenDatePicker}
                                        />

                                        {openDatePicker && (
                                            <div style={{zIndex: 20, position: "absolute"}}>
                                                <DateRange
                                                    locale={rdrLocales[lang]}
                                                    editableDateInputs={true}
                                                    onChange={(item) => setDate([item.selection])}
                                                    moveRangeOnFirstSelection={false}
                                                    ranges={date}
                                                    onFocus={() => {
                                                        gaEvents.customEvent('form_order_search', {
                                                            field_focus: 'Дата'
                                                        })
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center"
                                                    }}
                                                    className="btn-container"
                                                >
                                                    <i
                                                        i
                                                        style={{cursor: "pointer"}}
                                                        onClick={saveData}
                                                        class="fas fa-check-circle"
                                                    >
                                                        {props.getT("Применить")}
                                                    </i>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* Конец Формат даты по промежутку */}

                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label>{props.getT("Статус")}</label>
                                        <select
                                            className="form-control"
                                            value={props.form.status}
                                            defaultValue={props.form.status}
                                            onChange={(event) => {
                                                gaEvents.customEvent('form_order_search', {
                                                    field_focus: 'Статус'
                                                })
                                                props.changeFilterValue(
                                                    "status",
                                                    event.target.value,
                                                    props.form
                                                )
                                            }}

                                        >
                                            <option value={""}>{props.getT("Все")}</option>
                                            {props.status.map((e) => (
                                                <option key={e.id} value={e.id}>
                                                    {props.getT(e.name)}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                {/* Формат даты_готовности по промежутку */}
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        {visibleClearDate_production && (
                                            <i
                                                style={{marginRight: "10px", cursor: "pointer"}}
                                                onClick={clearData_production}
                                                class="fas fa-ban "
                                            >
                                                {props.getT("Сбросить")}
                                            </i>
                                        )}
                                        <label>{props.getT("Дата производства")}: </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            value={`${
                                                props.form?.date_production?.from || ""
                                            } ... ${props.form?.date_production?.to || ""}`}
                                            onClick={toggleOpenDate_productionPicker}
                                            onFocus={() => {
                                                gaEvents.customEvent('form_order_search', {
                                                    field_focus: 'Дата производства'
                                                })
                                            }}
                                        ></input>
                                        {openDate_productionPicker && (
                                            <div style={{zIndex: 20, position: "absolute"}}>
                                                <DateRange
                                                    locale={rdrLocales[lang]}
                                                    // locale={registerLocale("ru")}
                                                    editableDateInputs={true}
                                                    onChange={(item) =>
                                                        setDate_production([item.selection])
                                                    }
                                                    moveRangeOnFirstSelection={false}
                                                    ranges={date_production}

                                                />
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center"
                                                    }}
                                                    className="btn-container"
                                                >
                                                    <i
                                                        style={{cursor: "pointer"}}
                                                        onClick={saveData_production}
                                                        class="fas fa-check-circle"
                                                    >
                                                        {props.getT("Применить")}
                                                    </i>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* Конец Формат даты_готовности по промежутку */}

                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label>{props.getT("Менеджер")}</label>
                                        <select
                                            className="form-control"
                                            value={props.form.manager}
                                            defaultValue={props.form.manager}
                                            onChange={(event) => {

                                                gaEvents.customEvent('form_order_search', {
                                                    field_focus: 'Менеджер'
                                                })

                                                props.changeFilterValue(
                                                    "manager",
                                                    event.target.value,
                                                    props.form
                                                )
                                            }
                                            }
                                        >
                                            <option value={""}>{props.getT("Все")}</option>
                                            {props.managers.map((e) => (
                                                <option key={uuid()} value={e.id}>
                                                    {e.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>{props.getT("Клиент")}</label>
                                        <Select
                                            options={
                                                props.clients
                                                    ? props.clients.map((e) => {
                                                        return {
                                                            label: e.name + " " + "(" + e.phone + ")",
                                                            value: e.id
                                                        };
                                                    })
                                                    : ""
                                            }
                                            value={props.form.client}
                                            onChange={(selectedOption) => {
                                                gaEvents.customEvent('form_order_search', {
                                                    field_focus: 'Клиент'
                                                })
                                                props.changeFilterValue(
                                                    "client",
                                                    Number(selectedOption.value),
                                                    props.form
                                                )
                                            }
                                            }
                                            placeholder={props.getT("Введите имя или номер телефона")}
                                        />
                                    </div>
                                </div>


                                <div className="col-sm-12">
                                    <div className="">
                                    <span className="btn btn-danger"
                                          onClick={() => {

                                              props.clearData();
                                          }}
                                    >
                                        {props.getT("Очистить")}
                                    </span>
                                    </div>
                                </div>

                            </>

                        </FilterOrders>
                    </div>
                    <div className="card">

                        <div className="card-body p-0">
                            <div className="table-responsive">
                                <table className="table table-striped table-hover orders-table-main">
                                    <thead>
                                    <tr>
                                        <th scope="col">{props.getT("Тех. ID")}</th>
                                        <th scope="col">{props.getT("Название")}</th>
                                        <th scope="col">{props.getT("Клиент")}</th>
                                        <th scope="col">{props.getT("Менеджер")}</th>
                                        {/*<th>Клиент <sup><small>(в заказе)</small></sup></th>*/}
                                        <th scope="col">{props.getT("Статус")}</th>
                                        <th scope="col">{props.getT("Дата изменения")}</th>
                                        <th scope="col">{props.getT("Дата производства")}</th>
                                        <th scope="col">{props.getT("Действия")}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {renderOrders()}
                                    <tr>
                                        <td className={"text-center"} colSpan={8}>
                                            {props.getT("Список ограничен 30-ю свежайшими заказами. Если в списке" +
                                                " нету необходимого заказа - воспользуйтесь фильтрами. Фильтры" +
                                                " находяться над таблицей.")}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    );
};

export const OrderItem = (props) => {
    let ref = useDetectClickOutside({onTriggered: () => props.toggleChangeStatus(null)});
    const currency = useSelector(state => state.main.currency);
    const [isShowAccordionMaterialService, setShowAccordionMaterialService] = useState(false)
    const onClickShowAccordionMaterialService = () => {
        if (!isShowAccordionMaterialService) {
            gaEvents.customEvent('klik_form', {
                btn_order_item_show_material_service: 'display',
            })
        }
        setShowAccordionMaterialService(!isShowAccordionMaterialService)
    }

    const paymountStatus = useSelector(state => state.main.paymount_status);
    const [description, setDescription] = useState(false);
    const setOpenDescription = () => {
        setDescription(!description);
    };
    const status = useSelector((state) => state.auth.user.role);
    const roles = status.map((el) => el.name);

    const changeStatus = useMemo(() => {
        let access_change_status = {
            'status_8': [1],
            'status_2': [3],
            'status_3': [2],
        }
        let key_status = `status_${props?.order?.status_code}`;
        if (props.status && props.order && access_change_status?.[key_status]) {
            return props.status.filter(st => access_change_status?.[key_status]?.includes(st.id))
            // return props.status.filter(st => st.id !== Number(props.order.status_code)).filter((st) => st.id === 1 || st.id === 3);
        }
        return []
    }, [props.status, props.order])
    const isHideChangeStatus = () => {
        //скрить для меденжера изм статуса для проекта
        // true > hide
        //false > display
        if (status && props.order) {
            const isOnlyRoleManager = (status.length === 1) && status.find(item => Number(item.id) === 9)
            return isOnlyRoleManager ? (isOnlyRoleManager && [8, 2, 3].includes(Number(props.order.status_code))) : true
        } else {
            return true
        }
    }
    const canEdit =
        !roles.includes("CEO") && roles.includes("chief of production");

    const getStatus = (status) => {
        let status_data = props.status.find((e) => Number(e.id) === Number(status));
        if (status_data) {
            return status_data;
        } else {
            return {
                color: "danger",
                name: props.getT("Не указано")
            };
        }

    };

    const checkStatus = (user_roles, status_roles) => {
        let status = false;
        user_roles.forEach((e) => {
            if (status_roles.includes(Number(e.id))) {
                status = true;
            }
        });
        return status;
    };

    const dispatch = useDispatch();
    const changeProductionDateOrder = (order_id, date) =>
        dispatch(updateOrderProductionDateThunk(order_id, date, 'actual'));
    const onCacheReset = (params, call) => {
        dispatch(cacheResetThunc(params, call));
    }


    const openServiceNew = (code, version, client) => {
        //TODO: ссылка под замену (открытие заказа с СРМ)
        let urlProject = new ApiService()._baseUrlProject;

        const link =
            "https://newgo.ifurn.pro/project/" +
            code +
            "/" +
            '1' +
            "/" +
            client +
            "/" +
            localStorage.getItem("i_furn_user_firm") +
            "/" +
            localStorage.getItem("i_furn_user_id");

        window.open(
            urlProject +
            // "https://newgo.ifurn.pro/project/" +
            // "https://test-newgo.ifurn.pro/project/" +
            // "https://test2-newgo.ifurn.pro/project/" +

            // "http://go-local.ifurn.pro/project/" +
            code +
            "/" +
            '1' +
            "/" +
            client +
            "/" +
            localStorage.getItem("i_furn_user_firm") +
            "/" +
            localStorage.getItem("i_furn_user_id")
        );
    };
    let abc = "1";
    const onHandlerDeleteProjectId = ({id, code, v}) => {
        dispatch(onDeleteOrderProject({id, code, v}))
    }
    const handlerConfirmModal = ({message, onConfirm}) => (e) => {
        dispatch(ConfirmCommonModalAC({
                isOpen: true, message: message,
                onConfirm: onConfirm
            }
        ))
    }
    return (<>
            <tr key={props.order.id}>
                <td data-label={props.getT("TEX. ID")}>
        <span className={"track_status_order"}>
          {props.order &&
          (props.order.adress_delivery === "" ||
              !props.order.adress_delivery) ? (
              " "
          ) : (
              <i className="fas fa-truck-moving"></i>
          )}
        </span>
                    {Number(props.order.urgent) === 1 ? <span> &#128293; </span> : ""}
                    <span
                        className={'cursor-pointer tooltip-parent'}>
                        {!!props.order?.code && <>
                            <small className={"text-secondary"}>{props.order.code} </small>
                            <br/>
                        </>
                        }
                        {`${props.order?.id} [${props.order?.count_detail ?? 0}] ${!!Number(props.order.xnc_operations) ? '*' : ''}`}
                        <ul className="tooltip-bl order-count-detail">
                         {!!props.order?.code &&
                             <li><strong>code {props.getT('Заказа')}</strong>- {props.order.code}</li>}
                            <li> <strong>id {props.getT('Заказа')}</strong>- {props.order?.id}</li>
                        <li><strong>{props.getT('Количество деталей ')} </strong> - {props.order?.count_detail ?? 0}</li>
                    </ul></span>
                    <CodeOneC order={props.order} orders_check_enable={props.orders_check_enable} getT={props.getT}/>
                </td>
                <td data-label={props.getT("Название")}>{props.order.name}</td>
                {props.withClient ? (
                    <>
                        <ClientItem getT={props.getT} order={props.order} clients={props.clients}/>

                    </>
                ) : (
                    ""
                )}
                <ManegerItem getT={props.getT} order={props.order}/>
                {props.withStatus ? (
                    <td data-label={props.getT("Статус")}>
                        <div className="order_status_container pt-2" ref={ref}>
                            <span className={"badge bg-" + getStatus(props.order.status_code).color}>
                              {" "}
                                {props.order.status}

                            </span>
                            {getStatus(props.order.status_code).id !== 1 && isHideChangeStatus() && !!(changeStatus?.length) &&
                            props.order.confirm.isConfirmed &&
                            props.order.client_DB.client_DB_permit === Number(1) ? (
                                <i
                                    style={{cursor: "pointer"}}
                                    className="fas fa-edit"
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        props.toggleChangeStatus(
                                            Number(props.order.id) === Number(props.changedStatus)
                                                ? null
                                                : props.order.id
                                        )
                                    }
                                    }
                                > </i>
                            ) : (
                                ""
                            )}

                            {props.order.client_DB.client_DB_permit_text ? (
                                <>
                                    <span
                                        style={{
                                            fontSize: "24px",
                                            color: "red",
                                            cursor: "pointer",
                                            marginLeft: "5px"
                                        }}
                                        onClick={setOpenDescription}
                                    >
                                      &#9785;
                                    </span>
                                </>
                            ) : (
                                ""
                            )}
                            {localStorage.getItem("xnc") == 1 && isHideChangeStatus() && !!(changeStatus?.length) &&
                                props.order.client_DB.client_DB_permit_text && (
                                    <i
                                        style={{cursor: "pointer"}}
                                        className="fas fa-edit"
                                        onClick={() => {

                                            props.toggleChangeStatus(
                                                Number(props.order.id) === Number(props.changedStatus)
                                                    ? null
                                                    : props.order.id
                                            )
                                        }
                                        }
                                    ></i>
                                )}
                            {description && (
                                <>
                                    <StatusStartAutomatically getT={props.getT} order={props.order}/>
                                </>
                            )}
                            {!!(changeStatus?.length) && <div onClick={(e) => {
                                e.stopPropagation()
                            }}
                                                              className={
                                                                  "orders_status_change_container" +
                                                                  (Number(props.order.id) === Number(props.changedStatus)
                                                                      ? " active"
                                                                      : "")
                                                              }
                            >
                                <small>{props.getT("Установить новый статус")}:</small>
                                <hr/>
                                {changeStatus.map((s) => {
                                    if (checkStatus(props.roles, s.roles)) {
                                        return (
                                            <div
                                                key={uuid()}
                                                className={"change_status_variant"}
                                                onClick={() => {
                                                    gaEvents.customEvent('klik_form', {
                                                        order_change_status: 'change status'

                                                    })
                                                    props.updateOrder(props.order.id, {status: s.id})
                                                }
                                                }
                                            >
                      <span>
                        <span className={"badge bg-" + s.color}>{props.getT(s.name)}</span>
                      </span>
                                            </div>
                                        );
                                    }
                                })}
                            </div>}
                        </div>

                        <PaymountChoiceStatus paymentStatus={paymountStatus} status={props.order.payment_status}
                                              currency={currency?.filter(c => Number(c.currency_id) === Number(props.order?.currency))?.[0] || false}
                                              lang={props.lang}
                                              order_id={props.order.id} sumOrder={props.order?.summ_order}
                                              getT={props.getT} confirm_cost={props.order?.confirm?.cost}/>

                    </td>
                ) : (
                    ""
                )}
                <td data-label={props.getT("Дата изменения")}>{props.order.last_update}</td>
                <RowDateOfManufacture getT={props.getT} order={props.order}/>


                <td data-label={props.getT("Действия")}>
                    {(props.hasOwnProperty('isFeatureMaterialServiceAccordion') && props?.isFeatureMaterialServiceAccordion) &&
                        <>
                            <i title={props.getT('Материалы и услуги')}
                               style={{
                                   cursor: 'pointer',
                                   marginLeft: '5px',
                                   marginRight: '5px',
                                   transform: isShowAccordionMaterialService ? ' rotate(90deg)' : 'rotate(-90deg)'
                               }}
                               onClick={onClickShowAccordionMaterialService}
                               className={`fas fa-angle-left ${isShowAccordionMaterialService ? 'top' : 'bottom'}`}></i>
                        </>
                    }
                    {(props.order.program_link && props.order.program_link.link ) && <>
                        <a href={`${props.order.program_link.link}`} download={true} className="mr-1" title={`${props.getT("Программы и .project")} ${props.order.program_link?.time}`}>
                            <i className="fa fa-download" aria-hidden="true"></i>
                        </a>
                    </>}

                    {props.order.confirm.isConfirmed ? (
                        <a target={'_blank'} href={"/orders/detail/" + props.order.id} title={props.getT("Свойства")}
                           onClick={() => {
                               gaEvents.customEvent('klik_form', {
                                   click_link: 'open order detail',
                               })
                           }}>
                            <i className="far fa-eye"> </i>
                        </a>
                    ) : (
                        ""
                    )}


                    <>
                        {!canEdit ? (
                            <>

                                <span style={{marginLeft: "5px"}}
                                      title={props.getT("Открыть в редакторе")}
                                      onClick={() => {
                                          gaEvents.customEvent('klik_form', {
                                              click_link: 'open project newgo',
                                          })
                                          openServiceNew(
                                              props.order.code,
                                              props.order.last_project,
                                              props.order.client.id
                                          )
                                      }

                                      }
                                >
                  <i className="fas fa-external-link-alt order_open_service_link text-success">
                    {" "}
                  </i>
                </span>

                                <span
                                    title={props.getT("Отменить")}
                                    // onClick={() => {
                                    //     gaEvents.customEvent('klik_form', {
                                    //         order_change_status: 'change status'
                                    //     })
                                    //     props.updateOrder(props.order.id, {status: 8})
                                    // }}
                                    onClick={handlerConfirmModal({
                                        message: 'Отменить заказ?',
                                        onConfirm: () => {
                                            props.updateOrder(props.order.id, {status: 8})
                                        }
                                    })}
                                    className={"text-danger"}
                                >
                  <i className="fas fa-ban"></i>
                </span>
                            </>
                        ) : (
                            props.getT("Недостаточно прав")
                        )}
                    </>

                    {!canEdit &&
                    (

                        Number(props?.order?.status_code) === 2 || Number(props?.order?.status_code) === 8)
                        ? (
                            <span
                                // onClick={() => {
                                //     gaEvents.customEvent('klik_form', {
                                //         btn_order_edit: 'change status order 1',
                                //     })
                                //     props.updateOrder(props.order.id, {status: 1})
                                //
                                //
                                // }}
                                onClick={handlerConfirmModal({
                                    message: 'Вернуть в редактирование заказ?',
                                    onConfirm: () => {
                                        props.updateOrder(props.order.id, {status: 1})
                                    }
                                })}
                                className={"text-danger orders_action_icon_span"}
                                title={props.getT("Вернуть в редактирование")}
                            >
            <i className="fas fa-arrow-alt-circle-left"> </i>
          </span>
                        ) : (
                            ""
                        )}
                    {props.test === 1 ? (
                        <span
                            onClick={() => {
                                gaEvents.customEvent('klik_form', {
                                    btn_order_mark_readiness: 'change status order 7',
                                })
                                props.updateOrder(props.order.id, {status: 7})
                            }}
                            className={"text-success orders_action_icon_span"}
                            title={props.getT("Отметить готовность")}
                        >
            <i className="fas fa-arrow-alt-circle-right"> </i>
          </span>
                    ) : (
                        ""
                    )}
                    {/*//  сбрасывать кэш*/}
                    {AVAILABLE_RESET_CACHE.includes(Number(props.order.status_code)) &&
                        <span
                            title={props.getT("Сбросить кэшированные данные по заказу")}
                            // onClick={() => {
                            //     gaEvents.customEvent('klik_form', {
                            //         btn_order_rest_cache: 'reset cache order',
                            //     })
                            //     onCacheReset({firm: props.order.firm, order: props.order.id}, () => {
                            //         window.location.reload();
                            //     })
                            // }}
                            onClick={
                                handlerConfirmModal({
                                    message: 'Сбросить кэшированные данные по заказу?',
                                    onConfirm: () => {
                                        onCacheReset({firm: props.order.firm, order: props.order.id}, () => {
                                            window.location.reload();
                                        })
                                    }
                                })}
                        > <i className="fa fa-sync" style={{color: "#007bff", cursor: "pointer"}}> </i>
                </span>
                    }
                    {(Number(props?.order.status_code) === Number(1)) || (Number(props?.order.status_code) === Number(8)) ?
                        <span title={props.getT('Удалить заказ')}
                              style={{cursor: 'pointer'}}
                            // onClick={() => {
                            //     gaEvents.customEvent('klik_form', {
                            //         btn_order_remove: 'remove order',
                            //     })
                            //     onHandlerDeleteProjectId({
                            //         id: props?.order?.id,
                            //         code: props.order?.code,
                            //         v: props.order?.last_project
                            //     })
                            // }
                            // }
                              onClick={
                                  handlerConfirmModal({
                                      message: 'Удалить заказ?',
                                      onConfirm: () => {
                                          onHandlerDeleteProjectId({
                                              id: props?.order?.id,
                                              code: props.order?.code,
                                              v: props.order?.last_project
                                          })
                                      }
                                  })}
                        ><IconDelete/></span>
                        : ''
                    }
                </td>
            </tr>
            {props.hasOwnProperty('isFeatureMaterialServiceAccordion') && props?.isFeatureMaterialServiceAccordion && isShowAccordionMaterialService &&
                <>
                    {Number(props.order.status_code) !== 1 ? <>
                        <AccordionMaterialService lang={props.lang}
                                                  getT={props.getT}
                                                  data={props.order?.calc?.material?.filter(m => m.type === 'material') || []}
                                                  title={props.getT('Материалы')}/>

                        <AccordionMaterialService getT={props.getT}
                                                  lang={props.lang}
                                                  data={props.order?.calc?.material?.filter(m => m.type === 'serv') || []}
                                                  title={props.getT("Услуги")}
                                                  reversePrice={true}
                        />
                    </> : <tr>
                        <td colSpan={8}>{props.getT('Пока заказ в редактировании, список материалов и услуг не доступен')}</td>
                    </tr>}
                </>
            }
            {/*</tr>*/}
        </>
    );
};

// component ДАТА ПРОИЗВОДСТВ
const RowDateOfManufacture = ({getT, order}) => {
    const dispatch = useDispatch();
    const [openChangeDateProduction, changeDateProductionOpen] = useState(false);
    const changeProductionDateOrder = (order_id, date, callback) =>
        dispatch(updateOrderProductionDateThunk(order_id, date, false, callback));

    const [value, setValue] = useState(order.date_production)
    return (
        <td data-label={getT("Дата производства")}>
            {openChangeDateProduction &&
            order.status !== getT("в редактировании") ? (
                <>
                    <input
                        type={"date"}
                        defaultValue={order.date_production.substr(0, 10)}
                        onBlur={(event) => {
                            let [currentDate, time] = value.split(' ');
                            let choiceDate = new Date(event.target.value).valueOf();
                            let current = new Date(currentDate).valueOf();

                            if (!(choiceDate === current)) {
                                changeProductionDateOrder(order.id, event.target.value, () => {
                                    let data = event.target.value + " " + "09:00:00";
                                    setValue(data);
                                });
                            }
                            changeDateProductionOpen(false);

                        }}
                    />
                </>
            ) : (
                <>
            <span
                onDoubleClick={() =>
                    changeDateProductionOpen(!openChangeDateProduction)
                }
            >
              {value}
            </span>
                </>
            )}
        </td>
    )
}


const StatusStartAutomatically = ({getT, order}) => {
    const dispatch = useDispatch();
    const [isCheckedValue, setIsCheckedValue] = useState(Number(order.client_DB.status_auto));
    return (
        <div
            style={{
                position: "absolute",
                top: "35px",
                backgroundColor: "#fff",
                width: "400px",
                zIndex: 3,
                padding: "5px"
            }}
        >
            <span className="small mr-1"> {getT("Запустить автоматически")}</span>
            <input
                checked={Boolean(isCheckedValue)}
                type="checkbox"
                onChange={(event) => {
                    let checked = Number(event.target.checked);
                    let payload = {
                        id: order.id,
                        status: checked
                    };
                    dispatch(onChangeStatusAutomaticallyTaskThunk(payload, () => {
                        setIsCheckedValue(checked);
                    }));


                    // callback();
                }}
            />

            <div>{order.client_DB.client_DB_permit_text}</div>
        </div>
    )
}

const ClientItem = ({getT, order, clients}) => {
    const [clientName, setClientName] = useState(order.client.name_main);
    const [isVisible, setIsVisible] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [listPerson, setListPerson] = useState(false);
    const [choiseItem, setShoiseItem] = useState(false);
    const dispatch = useDispatch();
    let ref = useDetectClickOutside({onTriggered: () => setIsVisible(false)})

    const onSearchClients = (event) => {
        let value = event.target.value;
        const filtered = clients?.filter((client) => {
            if (value.length > 2) {
                return (
                    client?.name?.toLowerCase()?.includes(value.toLowerCase()) ||
                    client?.phone?.replace(/[^\d]+/g, "")?.includes(value)
                );
            }
        }) ?? [];
        gaEvents.customEvent('search', {
            search_term: value
        })
        setListPerson(filtered);
    }
    const changePeson = (clientId, orderId, name) => {
        gaEvents.customEvent('klik_form', {
            order_change_client: 'client'

        })
        dispatch(changeContragentDataThunc({"order_id": orderId, "order_change_client": clientId,}, () => {
            setClientName(name);
        }));

        setShoiseItem(prevState => {
            return prevState != clientId ? clientId : false
        });
    }
    return (
        <>
            <td data-label={getT("Клиент")}>
                <Link to={"/clients/detail/" + order.client.id}>
                    {clientName}
                </Link>
                <div className="change-personal" ref={ref}>
                    <span onClick={() => setIsVisible(!isVisible)}><i className="fas fa-edit"></i></span>
                    <div className={(isVisible) ? "client-search show" : "client-search"}>
                        <span className="close" onClick={() => setIsVisible(false)}>x</span>
                        <input type="text" defaultValue={searchValue} onChange={onSearchClients}/>
                        <table>
                            <thead>
                            <tr>
                                <th>{getT("id")}</th>
                                <th>{getT("Имя")}</th>
                                <th>{getT("Телефон")}</th>
                            </tr>
                            </thead>


                            {listPerson && <tbody style={{maxHeight: "250px", overflowY: "auto"}}>
                            {listPerson.map(item => {
                                return (
                                    <tr onClick={() => changePeson(item.id, order.id, item.name)}
                                        className={(choiseItem == item.id) ? 'active' : ''}>
                                        <td>{item.id}</td>
                                        <td>{item.name}</td>
                                        <td>{item.phone}</td>
                                    </tr>
                                )
                            })}
                            </tbody>}


                        </table>
                    </div>
                </div>
            </td>
        </>
    )
}

const ManegerItem = ({getT, order}) => {
    const manegers = useSelector(state => state.main.managers);
    const [managerName, setManagerName] = useState(order.manager.name);
    const [isVisible, setIsVisible] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [listPerson, setListPerson] = useState(false);
    const [choiseItem, setShoiseItem] = useState(false);
    const dispatch = useDispatch();
    let ref = useDetectClickOutside({onTriggered: () => setIsVisible(false)})

    const onSearchClients = (event) => {
        let value = event.target.value;
        const filtered = manegers?.filter((manager) => {
            if (value.length > 2) {
                return (
                    manager?.name?.toLowerCase()?.includes(value.toLowerCase()) ||
                    manager?.phone?.replace(/[^\d]+/g, "")?.includes(value)
                );
            }
        }) ?? [];
        gaEvents.customEvent('search', {
            search_term: value
        })
        setListPerson(filtered);
    }
    const changePeson = (clientId, orderId, name) => {
        gaEvents.customEvent('klik_form', {
            order_change_manager: 'manager'
        })
        dispatch(changeContragentDataThunc({"order_id": orderId, "order_change_manager": clientId,}, () => {
            setManagerName(name);
        }))
        setShoiseItem(prevState => {
            return prevState !== clientId ? clientId : false
        });
    }
    return (
        <td data-label={getT("Менеджер")}>{managerName}

            <div className="change-personal" ref={ref}>
                <span onClick={() => setIsVisible(!isVisible)}><i className="fas fa-edit"></i></span>
                <div className={(isVisible) ? "client-search show" : "client-search"}>
                    <span className="close" onClick={() => setIsVisible(false)}>x</span>
                    <input type="text" defaultValue={searchValue} onChange={onSearchClients}/>
                    <table>
                        <thead>
                        <tr>
                            <th>{getT("id")}</th>
                            <th>{getT("Имя")}</th>
                            <th>{getT("Телефон")}</th>
                        </tr>
                        </thead>


                        {listPerson && <tbody style={{maxHeight: "250px", overflowY: "auto"}}>
                        {listPerson.map(item => {
                            return (
                                <tr onClick={() => changePeson(item.id, order.id, item.name)}
                                    className={(choiseItem == item.id) ? 'active' : ''}>
                                    <td>{item.id}</td>
                                    <td>{item.name}</td>
                                    <td>{item.phone}</td>
                                </tr>
                            )
                        })}
                        </tbody>}

                    </table>
                </div>
            </div>
        </td>
    )
}

const PaymountChoiceStatus = ({status, paymentStatus, order_id, sumOrder = '', currency, getT, confirm_cost}) => {
    const dispatch = useDispatch();
    const production_vat = useSelector(getVatAddPriceIfMoreThan)
    const lang = useSelector(state => state.translation.local) || localStorage.getItem('lang')
    const [value, setValue] = useState({
        text: paymentStatus[status.code] ?? status.text,
        code: status.code
    });
    const onChange = (e) => {
        let dt = JSON.parse(e.target.value);
        setValue(() => ({
            text: dt.text,
            code: dt.code
        }));

        gaEvents.customEvent('klik_form', {
            order_change_payment_status: dt.code,
        })
        dispatch(onChangeOrderPaymountStatus({order_id, code: dt.code}))
    }
    return (
        <div className="paymount-status" style={{display: "flex", alignItems: 'center', marginTop: '10px'}}>
            {value.code !== 'no' ? <strong>
                    <IconPaid/> {sumOrder ? `(${sumOrder.toFixed(2)} ${currency ? currency?.name?.[lang] : ''})` : ''}
                </strong> :
                <strong style={{
                    marginRight: '8px',
                    fontSize: `${production_vat ? " 13px" : 'inherit'}`
                }}

                >$
                    {sumOrder ? `(${sumOrder.toFixed(2)} ${!!production_vat ? `+ ${production_vat}% ${getT("ндс")} = ${confirm_cost}` : ''} ${currency ? currency?.name?.[lang] : ''})` : ''}
                </strong>
            }
            <select style={{width: '100%'}}
                    className={'form-control'}
                    name="" id=""
                    onChange={onChange}>
                {paymentStatus && Object.entries(paymentStatus).map((p, key) => {
                    return <option selected={p[0] === value.code} key={key} value={JSON.stringify({
                        text: p[1],
                        code: p[0]
                    })}>{p[1]}</option>
                })}
            </select>
        </div>
    )
}

export default withLang(Orders);
