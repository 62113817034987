import React, {useMemo} from 'react';
import {useForm} from "react-hook-form";
import {SALARY_PROJECT_REPORT_PAGE} from "../../../store/actions/SalaryProject";
import Select from "react-select";
import Button from "../../../components/ui/Button";
import {LS_WORKER_ID} from "../../../utils/constants";
import {addSpacesToNumber} from "../../../utils/helpers";

const ReportFilter = ({currency, workers, getT, onSubmit, amount = null, currency_name=null}) => {
    let date = new Date();
    const worker_in_system = Number(localStorage.getItem(LS_WORKER_ID));
    const worker_def = useMemo(() => {
        if (!Array.isArray(workers) || !worker_in_system) return null
        let worker = workers?.find(e => Number(e.equipment_worker_id) === Number(worker_in_system))
        return {
            value: worker?.equipment_worker_id,
            label: worker?.name
        }
    }, [workers])
    const {register, handleSubmit, setValue, watch} = useForm({
        defaultValues: {
            worker: null,
            year_month: `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}`,
            currency: '',
            payed_only_for_sale: 0
        },
        values:{
            worker: worker_def
        }
    })
    let worker = watch('worker')
    let watch_currency = watch('currency')
    let payed_only_for_sale = watch('payed_only_for_sale')

    const summ = useMemo(() => {
        if (!amount) return 0
        let sum = addSpacesToNumber(Number(amount))
        return `${sum} ${currency_name}`
    }, [amount, watch_currency, currency]);

    return (
        <form className={"card px-2 py-2"} onSubmit={handleSubmit(onSubmit)}>
            <div className="d-flex  flex-wrap report-filter-row justify-content-between" style={{gap: '15px'}}>
                <div className="position-relative" style={{zIndex: '3'}}>
                    <div className="form-group">
                        <label>{getT("Работник")}</label>
                        <Select
                            placeholder={getT("Поиск")}
                            {...register("worker", {value: "worker"})}
                            value={worker}
                            options={workers?.filter((e) => {
                                if (!worker_in_system) return true
                                return   Number(e.equipment_worker_id) === Number(worker_in_system)
                            })?.map(item => {
                                return {
                                    value: item?.equipment_worker_id,
                                    label: item?.name
                                }
                            })}
                            onChange={(selectOptions) => {

                                setValue("worker", selectOptions)
                            }}
                        />
                    </div>
                </div>

                <div className="">
                    <div className="form-group">
                        <label>{getT("Валюта")}</label>
                        <select className="form-control" {...register("currency", {value: "currency"})}>
                            <option value={''}>--------</option>
                            {currency?.map(item => {
                                return <option value={item?.currency_id}>{item?.name}</option>
                            })}
                        </select>
                    </div>
                </div>
                <div className="">
                    <div className="form-group">
                        <label>{getT("Год/месяц")}</label>
                        <input className="form-control"
                               type="month"  {...register("year_month", {value: "year_month"})}/>
                    </div>
                </div>
                <div className="d-flex align-items-start flex-column justify-content-center">
                    <div className="custom-control custom-radio">
                        <input type="radio" className="custom-control-input" id="customControlValidation2" value={0}
                               checked={!Number(payed_only_for_sale)}
                               onChange={() => setValue("payed_only_for_sale", 0)}
                               name={"payed_only_for_sale"}/>
                        <label className="custom-control-label" htmlFor="customControlValidation2">{getT("Все")}</label>
                    </div>
                    <div className="custom-control custom-radio mb-3">
                        <input type="radio" className="custom-control-input" id="customControlValidation3"
                               name={"payed_only_for_sale"} value={1} checked={!!Number(payed_only_for_sale)}
                               onChange={() => setValue("payed_only_for_sale", 1)}
                        />
                        <label className="custom-control-label"
                               htmlFor="customControlValidation3">{getT("Отгруженные")}</label>
                    </div>
                </div>
                <div className="d-flex justify-content-end align-items-center btn-wrap">
                    <Button>{getT("Фильтровать")}</Button>
                </div>
            </div>
            {!!amount && <div className="row">
                <div className="col-12">
                    <h5>{getT("Итого")}: {summ}</h5>
                </div>
            </div>}
        </form>

    );
};

export default ReportFilter;