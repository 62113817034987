export const MODAL_PROJECT_GENERATE_CONFIRM = "MODAL/PROJECT_GENERATE_CONFIRM";
export const MODAL_PROJECT_GENERATE_HISTORY = "MODAL/PROJECT_GENERATE_HISTORY";


export const toggleModalProjectGenerateConfirmAC = ({isOpen, orderId}) => {
    return {
        type: MODAL_PROJECT_GENERATE_CONFIRM,
        payload: {isOpen, orderId: orderId}
    }
}
export const toggleModalProjectGenerateHistoryAC = ({isOpen, history}) => {
    return {
        type: MODAL_PROJECT_GENERATE_HISTORY,
        payload: {isOpen, history: history}
    }
}