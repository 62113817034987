import React from 'react';
import Modal from "../../../Modals/MyModal/Modal";
import {useDispatch, useSelector} from "react-redux";
import {getUserEmail} from "../../../../store/selectors/auth_selector";
import {getModalProjectGenerateHistory} from "../../../../store/selectors/modals_selector";
import {toggleModalProjectGenerateHistoryAC} from "../../../../store/actions/ModaslActions";

const ProjectGenerateHistoryModal = ({getT}) => {
    const dispatch = useDispatch();
    const modal = useSelector(getModalProjectGenerateHistory)


    const onClose = () => {
        dispatch(toggleModalProjectGenerateHistoryAC({isOpen: false, history: null}))
    }
    return (
        <Modal onClose={onClose} open={modal.isOpen} title={getT("История очереди на генерацию")}>
            <ul className="list-group text-left">
                {modal?.history?.map((item, index) => {
                    let mod = index % 2;
                    return (<li className={`list-group-item ${mod ? "list-group-item-secondary"  : ""}`}>
                        {item?.date_need} - {getT("дата запроса")}
                        <br/>
                        {item?.date_done} - {getT("дата генерации")}
                        <br/>
                        {item?.error} - {getT("комментарий")}
                    </li>)
                })}
            </ul>
        </Modal>
    );
};

export default ProjectGenerateHistoryModal;