import React, {useState, useEffect, useMemo} from "react";
import "../Orders/ResponsiveTable.css";
import PageTitle from "../PageTitle/PageTitle.jsx";

import Pagination from "./Pagination/Pagination";
import Test from "./Pagination/Test";
import {withLang} from "../../hoc/withLang";
import {useSelector} from "react-redux";

const Clients = (props) => {
    const [searchClient, setSearchClient] = useState("");
    const [findClient, setFindClient] = useState([props.clients]);
    const [currentPage, setCurrentPage] = useState(1);
    const [clientsPerPage] = useState(10);
    useEffect(() => {
        filterdata();
    }, [searchClient]);

    const filterdata = () => {
        if (props.clients) {
            let searchTerm = searchClient?.toLowerCase();
            const filtered = props.clients?.filter((client) => {
                let name = client?.name ? client?.name?.toLowerCase() : "";
                let phone = client?.phone ?? "";
                let email = client.email ? client?.email?.toLowerCase() : "";
                if (searchClient.length > 1) {
                    return (
                        name?.includes(searchTerm) || phone?.includes(searchClient) || email.includes(searchTerm)
                    );
                }
            });

            setFindClient(filtered);
        }
    };



    const indexOfLastClient = currentPage * clientsPerPage;
    const indexOfFirstClient = indexOfLastClient - clientsPerPage;
    const currentClient = findClient.slice(indexOfFirstClient, indexOfLastClient);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);



    return (
        <div className="content-wrapper">
            <PageTitle title={"clients"}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>{props.getT("Поиск Клиента")}: </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={props.getT("Введите имя или телефон")}
                                        value={searchClient}
                                        onChange={(e) => setSearchClient(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table m-0 table-striped table-hover">
                                    <thead>
                                    <tr>
                                        <th scope="col">{props.getT("ID")}</th>
                                        <th scope="col">{props.getT("Имя")}</th>
                                        <th scope="col">{props.getT("Телефон")}</th>
                                        <th scope="col">{props.getT("Email")}</th>
                                        <th scope="col">{props.getT("Статус")}</th>
                                        <th scope="col">{props.getT("Действия")}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <Test
                                        clients={currentClient}
                                        UdClient={props.updateClient}
                                    />
                                    {/*<tr>*/}
                                    {/*<td colSpan={6} className={'text-center'}>*/}
                                    {/*Отображены ближайшие 15 записей. Если Вы не нашли необходимого клиента - воспользуйтесь формой фильтру. Фильтры находятся вверху страницы*/}
                                    {/*</td>*/}
                                    {/*</tr>*/}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center mt-3">
                            <Pagination
                                clientsPerPage={clientsPerPage}
                                totalClients={findClient.length}
                                paginate={paginate}
                            />
                        </div>


                    </div>
                </div>
            </section>
        </div>
    );
};

export default withLang(Clients);
