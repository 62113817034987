import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getAdvancedGoodsServiceForOrderSelector} from "../../../store/selectors/advanced-goods-service-selector";
import OrderServicesExistingTable from "./OrderServicesExistingTable";
import {setExistingGoodsServiceForOrderModalAC} from "../../../store/actions/AdvancedGoodsServiceActions";
import SetGoodsServiceForOrderModal from "../../Modals/SetGoodsServiceForOrderModal";
import {deleteAdvancedGoodsServiceByIdForOrderIdThunk} from "../../../store/thunks/advansed-goods-service-thunk";

const availableOrderStatusForManipulate = [2]
const AdvancedGoodsServiceForOrder = ({getT, orderId, part, products, orderStatus, isEditService}) => {
    const dispatch = useDispatch();
    const goods_service_for_order = useSelector(getAdvancedGoodsServiceForOrderSelector);

    const onDeleteServiceForOrder = (id) => {
        dispatch(deleteAdvancedGoodsServiceByIdForOrderIdThunk(id, orderId))
    }

    const onOpenSetGoodsServiceModal = () => {
        dispatch(setExistingGoodsServiceForOrderModalAC({
            isOpen: true,
            orderId: orderId,
            products: products,
            part: part
        }));
    }
    let isEdit = availableOrderStatusForManipulate.includes(Number(orderStatus));
    let isVisibleBlock = (goods_service_for_order?.all?.length || goods_service_for_order?.existing?.length);
    if (!isVisibleBlock) {
        return null;
    }
    return (
        <>
            <tr className={"bg-lightgray"}>
                <td colSpan="7" className={"text-center"}>
                    <b>{getT('Дополнительные услуги к заказу')}</b>
                    {(isEdit && isEditService) &&
                        <button onClick={onOpenSetGoodsServiceModal}
                                className={"ml-2 btn btn-success btn-sm"}>{getT("Добавить услугу")}</button>
                    }
                </td>
            </tr>
            <OrderServicesExistingTable getT={getT} onDeleteService={onDeleteServiceForOrder}
                                        services={goods_service_for_order?.existing ?? null}
                                        isEdit={(isEdit && isEditService)}
            />

            <SetGoodsServiceForOrderModal getT={getT}/>

        </>
    );
};

export default AdvancedGoodsServiceForOrder;