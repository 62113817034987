import {
    FETCH_ALL_ADVANCED_GOODS_SERVICES_FOR_ORDER,
    SET_GOODS_SERVICES_FOR_ORDER_MODAL
} from "../actions/AdvancedGoodsServiceActions";

const InitialState = {

    goods_service_for_order: {
        all: null,
        existing: null,
    },
    set_goods_service_for_order_modal: {
        orderId: null,
        part: null, products: null,
        isOpen: false,
    }
}

const AdvancedGoodsServiceReducer = (state = InitialState, action) => {
    switch (action.type) {
        case FETCH_ALL_ADVANCED_GOODS_SERVICES_FOR_ORDER: {
            return {
                ...state,
                goods_service_for_order: action.payload
            }
        }
        case SET_GOODS_SERVICES_FOR_ORDER_MODAL: {
            return {
                ...state,
                set_goods_service_for_order_modal: {
                    ...state.set_goods_service_for_order_modal,
                    ...action.payload,
                }
            }
        }

        default: {
            return {
                ...state,
            }
        }
    }
}

export default AdvancedGoodsServiceReducer;
