import React, {useEffect, useState} from 'react';
import useClickOutside from "../../../hook/useClickOutside";
import ButtonPrint from "../OrderDetailPrint/ButtonPrint";
import {gaEvents} from "../../../gaEvents";
import {useDispatch} from "react-redux";
import {getPDFLoaderSetterThunk} from "../../../store/reducers/MainReducer";
import ProjectGenerateConfirmModal from "./Modal/ProjectGenerateConfirmModal";
import {
    toggleModalProjectGenerateConfirmAC,
    toggleModalProjectGenerateHistoryAC
} from "../../../store/actions/ModaslActions";
import ProjectGenerateHistoryModal from "./Modal/ProjectGenerateHistoryModal";

const ProjectDownloadBlock = ({order, getT,}) => {


    const dispatch = useDispatch();
    const {setVisible, ref, isVisible} = useClickOutside();
    const [oldBtn, setOldBtn] = useState(false)
    useEffect(() => {
        const onPressKey = (event) => {
            if ((event.altKey && event.keyCode === 71)) {
                setOldBtn(true);
            }
        }
        window.addEventListener('keydown', onPressKey)
        return () => window.removeEventListener('keydown', onPressKey)
    }, [oldBtn]);
    let program_link = order.program_link;

    const onOpenGenerateConfirmModal = () => {
        dispatch(toggleModalProjectGenerateConfirmAC({isOpen: true, orderId: order.id}))
    }
    const onOpenGenerateHistoryModal = () => {
        dispatch(toggleModalProjectGenerateHistoryAC({isOpen: true, history: program_link.history}))
    }
    const onGenerateProject = () => {
        gaEvents.customEvent('klik_form', {
            gibsaw_ftp: 'Download Программы и .project',
        })
        dispatch(getPDFLoaderSetterThunk('gibsaw_ftp', order))
    }

    return (<>
            <div className={"print_all_documents_main_container"} ref={ref}>
                <button
                    className="btn btn-app bg-info h-100 w-100"
                    onClick={() => setVisible(true)}
                >
                    <span className="txt">{getT("Программы и .project")}</span>
                </button>


                <div
                    className={
                        "print_all_documents_container " +
                        (isVisible
                            ? "open"
                            : "")
                    }
                >

                    {(program_link && program_link.link) &&
                        <a href={`${program_link.link}`} download={true}
                           style={{gap: "6px", color: "#19692c", lineHeight: '18px'}}
                           className="mb-1 border-bottom pb-1 d-flex align-items-center font-weight-normal justify-content-start">
                            <i className="fa fa-download" aria-hidden="true"></i>
                            <span className={"d-block text-left"}>
                               {getT("Скачать")}
                                <br/> {order.program_link?.time}
                        </span>
                        </a>
                    }
                    {program_link?.history?.length && <div className="mb-1 pb-1 border-bottom">
                        <div className="text-left cursor-pointer font-weight-normal "
                             onClick={onOpenGenerateHistoryModal}>
                            {getT("История очереди на генерацию")}
                        </div>
                    </div>}
                    {!!Number(program_link?.queue?.number) ?
                        <div className="mb-1 pb-1 ">
                            <div className="text-left cursor-pointer font-weight-normal text-black" style={{color:"black"}}>
                                {getT("Заказ в очереди на генерацию")} - #{program_link?.queue?.number}
                            </div>
                        </div>
                        :
                   <div className="mb-1 pb-1">

                        <div className="text-left cursor-pointer font-weight-normal" style={{color:"black"}}
                             onClick={onOpenGenerateConfirmModal}>
                            {getT("Поставить заказ в очередь на генерацию")}
                        </div>
                    </div>}


                    {oldBtn && <div className="mb-1 pb-1">
                        <div className="text-left cursor-pointer font-weight-normal" onClick={onGenerateProject}
                             style={{lineHeight: '18px'}}

                        >
                            <span className="text-warning">{getT("Сгенерировать программы и .project")}</span>
                        </div>
                    </div>}
                </div>
            </div>

            <ProjectGenerateConfirmModal getT={getT}/>
            <ProjectGenerateHistoryModal getT={getT}/>
        </>
    );
};


export default ProjectDownloadBlock;