import React, {useMemo} from 'react';
import {useDispatch} from "react-redux";
import getT from "../../../printHtml/getT";
import ButtonPrint from "../OrderDetailPrint/ButtonPrint";
import {gaEvents} from "../../../gaEvents";
import {LABELS_CUTTING_PRINT} from "../../../store/reducers/MainReducer";
import OrderDetailPrintGl from "../OrderDetailPrint/OrderDetailPrintGl";
import DetailPrintDocuments from "./DetailPrintDocuments";
import ApiService from "../../../api/api";
import {LS_LANG} from "../../../utils/constants";
import {printHtmlReportPazThunk} from "../../../store/thunks/print-html-thunk";
import ProjectDownloadBlock from "./ProjectDownloadBlock";

const CLASS_NAME_FILE_ALT = "far fa-file-alt"
const locales = {
    ua: 'uk',    // Украинский
    ru: 'ru',    // Русский
    pl: 'pl',    // Польский
    ro: 'ro',    // Румынский
    en: 'en',    // Английский
    he: 'he',    // Иврит
    de: 'de',    // Немецкий
    lv: 'lv',    // Латышский
    lt: 'lt',    // Литовский
    sk: 'sk',    // Словацкий
    et: 'et'     // Эстонский
};
let glLink = new ApiService()._glLink;

const OrderControlWrapper = ({
                                 getT,
                                 order,
                                 getPDF,
                                 openService,
                                 modalCuttingCard,
                                 getGiblabDataThunk,
                                 handlerUpdateSettingsInOrder,
                                 handlerShortFormOrder, modalPackagingPrinting, onChangeValueModal, onSetFileManager
                             }) => {
const dispatch = useDispatch();




    const ENTRY_FOR_CALCULATE_VALUES = {
        [getT("Открыть в редакторе")]: {
            is_display: !!order?.project_data?.calculate_vals,
            component: <ButtonPrint getT={getT}
                                    onClick={() => {
                                        gaEvents.customEvent('klik_form', {
                                            btn_open_service: 'Открыть в редакторе',
                                        })
                                        openService()
                                    }}
                                    typeActionChildren={''}
                                    isFileIcon={false}
                                    titleChildren={<>{getT("Открыть в редакторе")}</>}
            />
        },
        [getT("Бланк заказа")]: {
            is_display: !!order?.project_data,
            component: <ButtonPrint getT={getT}
                                    onClick={() => {
                                        gaEvents.customEvent('klik_form', {
                                            order_blank_short_html: 'Бланк заказа',
                                        })
                                        getPDF("order_blank_short_html", order.project_data)
                                    }}
                                    typeActionChildren={''}
                                    isFileIcon={true}
                                    titleChildren={<>{getT("Бланк заказа")}</>}
            />
        },
        [getT('Бланк заказа производство')]: {
            is_display: !!order?.project_data?.calculate_vals,
            component: <ButtonPrint getT={getT}
                                    onClick={() => {
                                        gaEvents.customEvent('klik_form', {
                                            order_blank_short_without_html: 'Бланк заказа производство',
                                        })
                                        getPDF("order_blank_short_without_html", order.project_data)
                                    }}
                                    typeActionChildren={''}
                                    isFileIcon={true}
                                    titleChildren={<>{getT("Бланк заказа")}
                                        <br/> {getT("(производство)")}</>}
            />
        },
        [getT('Сводный отчет ЧПУ')]: {
            is_display: !!order?.project_data,
            component: <ButtonPrint getT={getT}
                                    onClick={() => {
                                        gaEvents.customEvent('klik_form', {
                                            btn_order_blank: 'Сводный отчет ЧПУ',
                                        })
                                        getPDF("order_blank", order.project_data)
                                    }}
                                    typeActionChildren={''}
                                    isFileIcon={true}
                                    titleChildren={<>{getT("Сводный отчет")}
                                        <br/> {getT("ЧПУ")}</>}
            />
        },
        [getT('Чертежи ЧПУ операций')]: {
            is_display: !!order?.project_data?.calculate_vals && !!Number(order.xnc_operations),
            component: <ButtonPrint getT={getT}
                                    onClick={() => {
                                        gaEvents.customEvent('klik_form', {
                                            ifp_xnc: 'Чертежи ЧПУ операций',
                                        })
                                        getPDF("ifp_xnc", order.project_data)
                                    }}
                                    typeActionChildren={''}
                                    isFileIcon={true}
                                    titleChildren={<>{getT("Чертежи ЧПУ")}<br/>{getT("операций")}</>}
            />
        },
        [getT('Накладная на сырье')]: {
            is_display: !!order?.project_data,
            component: <ButtonPrint getT={getT}
                                    onClick={() => {
                                        gaEvents.customEvent('klik_form', {
                                            material_list_html: 'Накладная на сырье',
                                        })
                                        getPDF("material_list_html", order.project_data)
                                    }}
                                    typeActionChildren={''}
                                    isFileIcon={true}
                                    titleChildren={<>{getT("Накладная на сырье")}</>}
            />
        },
        [getT('Обновить раскрой API')]: {
            is_display: !!order?.project_data,
            component: <ButtonPrint getT={getT}
                                    onClick={() => {
                                        gaEvents.customEvent('klik_form', {
                                            renew_cards_crm: 'Обновить раскрой API',
                                        })
                                        getGiblabDataThunk("renew_cards_crm", order)
                                    }}
                                    typeActionChildren={<>{getT("")}</>}
                                    isFileIcon={true}
                                    titleChildren={<>{getT("Обновить раскрой")}</>}
            />
        },
        [getT('Спецификация (полная)')]: {
            is_display: !!(order.project_data?.part?.length && order.project_data?.cutting_card),
            component: <ButtonPrint getT={getT}
                                    onClick={() => {
                                        gaEvents.customEvent('klik_form', {
                                            cutting_card: 'Спецификация (полная)',
                                        })
                                        handlerShortFormOrder({
                                            order: order.project_data,
                                            full: true
                                        })
                                    }}
                                    typeActionChildren={''}
                                    isFileIcon={true}
                                    titleChildren={<>{getT("Спецификация (полная)")}</>}
            />
        },
        [getT('Спецификация (деталировка)')]: {
            is_display: !!(order.project_data?.part?.length),
            component: <ButtonPrint getT={getT}
                                    onClick={() => {
                                        gaEvents.customEvent('klik_form', {
                                            cutting_card: 'Спецификация (деталировка)',
                                        })
                                        handlerShortFormOrder({
                                            order: order.project_data,
                                            full: false
                                        })
                                    }}
                                    typeActionChildren={''}
                                    isFileIcon={true}
                                    titleChildren={<>{getT("Спецификация (деталировка)")}</>}
            />
        },
        [getT('Карты раскроя')]: {
            is_display: !!(order.project_data?.cutting_card?.hasOwnProperty('cards')),
            component: <ButtonPrint getT={getT}
                                    onClick={() => {
                                        gaEvents.customEvent('klik_form', {
                                            cutting_card: 'Карты раскроя',
                                        })
                                        modalCuttingCard(true)
                                    }}
                                    typeActionChildren={''}
                                    isFileIcon={true}
                                    titleChildren={<>{getT("Карты раскроя")}</>}
            />
        },
        [getT("Упаковки")]: {
            is_display: !!(order.project_data?.hasOwnProperty('packing') && Array.isArray(order.project_data?.packing) && order.project_data?.packing?.length),
            component: <ButtonPrint getT={getT}
                                    onClick={() => {
                                        gaEvents.customEvent('klik_form', {
                                            packing: 'Упаковки',
                                        })
                                        modalPackagingPrinting(true)
                                    }}
                                    typeActionChildren={''}
                                    isFileIcon={true}
                                    titleChildren={<>{getT("Упаковка")}</>}
            />
        },
        [getT("Этикетки")]: {
            is_display: !!order?.project_data?.calculate_vals,
            component: <ButtonPrint getT={getT}
                                    onClick={() => {
                                        gaEvents.customEvent('klik_form', {
                                            label: 'Этикетки',
                                        })
                                        onChangeValueModal({
                                            isOpen: true,
                                            action: LABELS_CUTTING_PRINT.ifurn
                                        })
                                    }}
                                    typeActionChildren={''}
                                    isFileIcon={true}
                                    titleChildren={<>{getT("Этикетки")}</>}
            />
        },
        [getT("Изменить раскрой")]: {
            is_display: !!order?.project_data && !!order?.project_data?.calculate_vals && order?.project_data?.code,
            component: <a
                className="btn btn-app bg-info"
                href={`${glLink}?code=${order.project_data.code}&cs=1`}
                target={"_blank"}
                onClick={() => {
                    gaEvents.customEvent('klik_form', {
                        btn_giblab: 'Изменить раскрой',
                    });
                }}
            >
                <span className="badge bg-danger">{getT("Giblab")}</span>
                <i className="far fa-file-alt"></i> <span
                className="txt">{getT("Изменить раскрой")}</span>
            </a>
        },
        ["GL"]: {
            is_display: !!order?.project_data?.calculate_vals,
            component: <OrderDetailPrintGl getT={getT} order={order}
                                           onClicklLabels={() => {
                                               onChangeValueModal({
                                                   isOpen: true,
                                                   action: LABELS_CUTTING_PRINT.giblab
                                               })
                                           }
                                           }
                                           getPdfHandler={getPDF}/>
        },
        [getT("Печать пакета документов")]: {
            is_display: !!order?.project_data?.calculate_vals,
            component: <DetailPrintDocuments getT={getT}
                                             order={order.project_data}/>
        },
        [getT("Программы и .project")]: {
            is_display: !!order?.project_data?.calculate_vals,
            component: <ProjectDownloadBlock order={order.project_data} getT={getT}/>
        },
        [getT("Скачать IFP project")]: {
            is_display: !!order?.project_data,
            component: <ButtonPrint getT={getT} onClick={() => {
                gaEvents.customEvent('klik_form', {
                    get_ifp_project: 'Download Скачать IFP project',
                })
                getPDF("get_ifp_project", order.project_data)
            }}
                                    typeActionChildren={<>{getT("Download")}</>}
                                    isFileIcon={true}
                                    titleChildren={<>{getT("Скачать IFP project")}</>}
            />
        },
        [getT("Файлы заказа")]: {
            is_display: !!order?.project_data?.calculate_vals,
            component: <a
                className={
                    order.file_from_user === 0 ? "btn btn-app bg-info" : "btn btn-app bg-danger"
                }
                onClick={() => onSetFileManager({
                    isOpen: true,
                    idProject: order.code
                })
                }
            >
                        <span
                            className={
                                order.file_from_user === 0
                                    ? "badge bg-danger"
                                    : "badge bg-info"
                            }
                        >
                          {getT("Download")}
                        </span>
                <i className="far fa-file-alt"></i> {getT("Файлы заказа")}
            </a>
        },
        [getT("Обновить настройки в заказе")]: {
            is_display: !!order?.project_data,
            component: <ButtonPrint getT={getT} onClick={() => {
                handlerUpdateSettingsInOrder(order?.project_data?.id)
            }}
                                    typeActionChildren={<>{getT("")}</>}
                                    isFileIcon={false}
                                    titleChildren={<>{getT("Обновить настройки в заказе")}</>}
            />
        },
        [getT("Отчёт по пазам")]: {
            is_display: !!order?.project_data && !!(order.project_data?.part?.length),
            component: <ButtonPrint getT={getT} onClick={() => {
                dispatch(printHtmlReportPazThunk(order.project_data))
                // handlerUpdateSettingsInOrder(order?.project_data?.id)
            }}
                                    typeActionChildren={<>{getT("")}</>}
                                    isFileIcon={true}
                                    titleChildren={<>{getT("Отчёт по пазам")}</>}
            />
        }

    }
    const sortObjectControlsByKeyTitle = useMemo(() => {

        try {
            let result = Object.keys(ENTRY_FOR_CALCULATE_VALUES)
                .filter(key => !!ENTRY_FOR_CALCULATE_VALUES[key].is_display)
                .sort((a, b) => {
                    let a_chartOne = a?.trim()?.[0]
                    let b_chartOne = b?.trim()?.[0]
                    let locale = locales[localStorage.getItem(LS_LANG)]
                    // Указываем локаль для сортировки (например, для русского, английского, польского и немецкого)
                    return a_chartOne.localeCompare(b_chartOne, locale, {sensitivity: "base", ignorePunctuation: true});
                })  // Сортируем ключи
                .reduce((acc, key) => {
                    let item = ENTRY_FOR_CALCULATE_VALUES[key]
                    acc.push(item.component);  // Создаем новый объект с отсортированными ключами
                    return acc;
                }, []);
            return result
        } catch (e) {
            return null
        }
    }, [])
    if (!order.hasOwnProperty('project_data') || !order.project_data) return null
    return (
        <div className={"order-detail-pdf__items"}>
            {sortObjectControlsByKeyTitle ? sortObjectControlsByKeyTitle?.map(component => {
                return component
            }) : null}
        </div>
    );
};

export default OrderControlWrapper;