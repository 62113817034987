/**
 * Сгруппировать детали в доп. услуги.
 * @param {object} order -
 * @returns  {array} goods_service_added_firm  -
 */

function trimToFiveDecimals(number) {
    // Check if the input is a finite number
    if (Number.isFinite(number)) {
        // Round the number to 5 decimal places
        return parseFloat(number.toFixed(5));
    } else {
        // Return the original value if the input is not a number
        return number;
    }
}

export const getGoodsServiceOrderCountCalculateWithType = (goods_service, part, products) => {
    let count_of_part = !!Number(goods_service.count_connected_to_count_of_part)
    let volume_of_offer = !!Number(goods_service.count_connected_to_volume_of_offer)

    let result = {value: 0, is_edit: true};
    if (count_of_part) {
        let count_of_part_values = part?.reduce((acc, item) => {
            let part_count = Number(item.count)
            let find_product_count = products?.find(prod => Number(prod?.id) === Number(item?.product))?.count || 1;
            return Number(acc) + (Number(part_count) * Number(find_product_count))
        }, 0)
        return {
            value: trimToFiveDecimals(count_of_part_values),
            is_edit: false
        }
    }
    if (volume_of_offer) {
        let count_of_part_values = part?.reduce((acc, item) => {
            let part_x = Number(item.x1)
            let part_y = Number(item.x1)
            let part_z = Number(item.z)
            let part_count = Number(item.count)
            let find_product_count = products?.find(prod => Number(prod?.id) === Number(item?.product))?.count || 1;
            let part_size = (part_x * part_y * part_z) / 1000 / 1000 / 1000

            let value_count = ((part_size * part_count) * Number(find_product_count))

            return Number(acc) + Number(value_count)
        }, 0)
        return {
            value: trimToFiveDecimals(count_of_part_values),
            is_edit: false
        }
    }
    return result
}

