import React from 'react';
import Modal from "../../../Modals/MyModal/Modal";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {getUserEmail} from "../../../../store/selectors/auth_selector";
import {getModalProjectGenerateConfirm} from "../../../../store/selectors/modals_selector";
import {toggleModalProjectGenerateConfirmAC} from "../../../../store/actions/ModaslActions";
import {orderGenerateXncToQueueThunk} from "../../../../store/thunks/order_detail_thunk";

const ProjectGenerateConfirmModal = ({getT}) => {
    const dispatch = useDispatch();
    const modal = useSelector(getModalProjectGenerateConfirm)
    const userEmail = useSelector(getUserEmail);

    const {register, handleSubmit} = useForm();
    const onSubmit = (data) => {
        console.log(data)
        dispatch(orderGenerateXncToQueueThunk(modal.orderId, data.email))
    }
    const onClose = () => {
        dispatch(toggleModalProjectGenerateConfirmAC({isOpen: false, orderId: null}))
    }
    return (
        <Modal onClose={onClose} open={modal.isOpen} title={getT("Поставить заказ в очередь на генерацию")}>
            <form onSubmit={handleSubmit(onSubmit)} className={"text-center"}>
                <div className="form-group text-left">
                    <label className={""}>{getT("Отправить копию на email")}</label>
                    <input className={"form-control"} {...register("email", {value: userEmail})} placeholder="email"/>
                </div>
                <button className={"btn btn-primary"}>{getT("Отправить")}</button>
            </form>
        </Modal>
    );
};

export default ProjectGenerateConfirmModal;