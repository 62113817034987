import {MODAL_PROJECT_GENERATE_CONFIRM, MODAL_PROJECT_GENERATE_HISTORY} from "../actions/ModaslActions";

const INITIAL_STATE = {
    projectGenerateConfirm: {
        isOpen: false,
        orderId: null
    },
    projectGenerateHistory: {
        isOpen: false,
        history: null
    }
}

const ModalsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MODAL_PROJECT_GENERATE_CONFIRM: {
            return {
                ...state,
                projectGenerateConfirm: {
                    ...action.payload
                }
            }
        }
        case MODAL_PROJECT_GENERATE_HISTORY: {
            return {
                ...state,
                projectGenerateHistory: {
                    ...action.payload
                }
            }
        }
        default: {
            return state
        }
    }
}
export default ModalsReducer;