import {instances, URI_API_EXTTUNELL} from "../Salary/setting_api";
import {rejectTransform, resolveTransform} from "../utils_errors";
import {createFormData} from "../../utils/helpers";

/**
 * Базовые справочники
 * @method get  - список пользователей
 */
const order_production_update = "order_production_update"
const order_generate_xnc_to_queue = "order_generate_xnc_to_queue"
export const CommonOrderAPI = {
    update_setting_in_order(form_data) {
        return instances.post(URI_API_EXTTUNELL, form_data, {
            headers: {
                action: order_production_update
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    orderGenerateXncToQueue (form_data){

        return instances.post(URI_API_EXTTUNELL, form_data, {
            headers: {
                action: order_generate_xnc_to_queue
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    }

}