import {setOrderDetailDataThunk, toggleLoaderStatusAC} from "../reducers/MainReducer";
import {dispatcherErrorThunk} from "./common_thunk";
import {PutProdMarkDetailAPI} from "../../api/Salary/PutProdMarkDetailAPI";
import {createFormData} from "../../utils/helpers";
import {ShortOrderHtml} from "../../printHtml/ShortOrderHtml";
import {shortOrderFormWindowHtml, unpackingDetailInOrderWindowHtml} from "../../printHtml";
import {SalaryShortOrderHtml} from "../../printHtml/SalaryShortOrderHtml";
import {CommonOrderAPI} from "../../api/Order/CommonOrderAPI";
import toast from "react-hot-toast";
import {toggleModalProjectGenerateConfirmAC} from "../actions/ModaslActions";
import {SalaryUnpackingDetailInOrderHtml} from "../../printHtml/SalaryUnpackingDetailInOrderHtml";
import QRCode from "qrcode"

/**
 * @description {orderDetailDataSheetPrintThunk}  Отобразить полную спецификацию отметок о заказе для печати
 * @param {boolean}  is_pack - флаг отобразить упаковку
 * @param {boolean}  is_service - флаг отобразить услги
 */
//::TODO - добавить флаг для отображения не упакованных деталей в проекте (уточить в Ильи)
export const orderDetailDataSheetPrintThunk = ({is_pack = false, is_service = false}) => async (dispatch, getState) => {
    try {
        let modal = getState().main.sheet_data_print_confirm_modal;
        let logo_state = getState()?.auth?.user?.logo;
        let order_mark = null;
        let form_data = createFormData({id: modal?.order?.id})
        dispatch(toggleLoaderStatusAC(true));
        if (is_pack || is_service) {
            let response = await PutProdMarkDetailAPI.getPartForOrderMarks(form_data);
            if (!!response.error) {
                dispatch(dispatcherErrorThunk(response))

                return false
            }
            if (!response?.error) {
                order_mark = response?.data;
            }
        }
        let response_order_parts = await PutProdMarkDetailAPI.qrGetForOrder(form_data);
        if (!response_order_parts?.error && response_order_parts?.data) {
            let orderPartsQr = await _mappingOrderDetailGenerateQRCode(response_order_parts?.data);
            let partUpdate = modal.order?.part?.map(part => {
                let findQr = orderPartsQr?.parts?.find(e => e?.info?.id === part.id);

                return {
                    ...part,
                    qrCode: findQr?.qrCode ?? null,
                    link: findQr?.link ?? null,
                }
            })

            modal.order = {
                ...modal.order,
                part: partUpdate,
            };
        }

        const content_html = new ShortOrderHtml().generateContentHtml({
            order: modal.order,
            full: modal.full,
            logo: logo_state,
            company_name: '',
            order_mark: order_mark,
            is_pack: is_pack, is_service: is_service
        });
        shortOrderFormWindowHtml.openWindowPrint({content: content_html, order: modal?.order});

        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        console.log(e?.stack)
        dispatch(dispatcherErrorThunk(e))
    }
}
/**
 * @description {generateOrderDetailDataSheetPrintThunk} - Отобразить краткую спецификацию отметок о заказе для печати
 *
 * @param {number} order_id  - номер заказа
 * @param {boolean} settingViewPacking  - флаг отобразить упакованные детали в заказе
 * @param {boolean} settingViewUnpacked  -флаг отобразить не упакованные детали в заказе

 */


const _mappingOrderDetailGenerateQRCode = async (data) => {
    try {
        let _mappingQrCodes = async (item) => {
            let qrCode = item?.link ? await QRCode.toDataURL(item?.link) : null;

            return {
                ...item, qrCode: qrCode
            }
        }
        let parts = Array.isArray(data?.parts) ?
            await Promise.all(data?.parts?.map(async (item) => {
                return _mappingQrCodes(item)
            })) : data?.parts;
        return {
            ...data, parts: parts
        }
    } catch (e) {
        return data
    }
}
export const generateOrderDetailDataSheetPrintThunk = ({
                                                           order_id,
                                                           settingViewPacking = true,
                                                           settingViewUnpacked = false
                                                       }) => async (dispatch, getState) => {
    try {
        let logo_state = getState()?.auth?.user?.logo;
        let order_mark = null;
        let form_data = createFormData({id: order_id})
        dispatch(toggleLoaderStatusAC(true));
        let response_order_marks = await PutProdMarkDetailAPI.getPartForOrderMarks(form_data);
        let response_order_parts = await PutProdMarkDetailAPI.qrGetForOrder(form_data);
        let error = response_order_marks?.hasOwnProperty('error') && !!response_order_marks?.error && !!Object.keys(response_order_marks?.error)?.length ? response_order_marks?.error : null
        let error_part = response_order_parts?.hasOwnProperty('error') && !!response_order_parts?.error && !!Object.keys(response_order_parts?.error)?.length ? response_order_parts?.error : null
        if (error || error_part) {
            let error_msg = error || error_part
            dispatch(dispatcherErrorThunk({error: error_msg}))
            return false
        }

        let orderPartsQr = await _mappingOrderDetailGenerateQRCode(response_order_parts?.data)

        if (settingViewPacking) {
            const content_html = new SalaryShortOrderHtml()
                .generateContentHtml({
                    order: response_order_marks?.data,
                    logo: logo_state,
                    company_name: '',
                    order_parts: orderPartsQr,


                });
            shortOrderFormWindowHtml.openWindowPrint({content: content_html, order: {id: order_id}});
        }

        if (settingViewUnpacked) {
            const content_html = new SalaryUnpackingDetailInOrderHtml()
                .generateContentHtml({
                    order: response_order_marks?.data,
                    logo: logo_state,
                    company_name: '',
                    order_parts: orderPartsQr,
                });
            unpackingDetailInOrderWindowHtml.openWindowPrint({content: content_html, order: {id: order_id}})
        }
        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        console.log(e)
        dispatch(dispatcherErrorThunk(e))
    }
}

export const updateSettingsInOrderThunk = (order_id) => async dispatch => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        const formData = createFormData({id: order_id})
        const response = await CommonOrderAPI.update_setting_in_order(formData);
        if (!!response.error) {
            dispatch(dispatcherErrorThunk(response))
            return false
        }
        toast.success("OK")
        dispatch(toggleLoaderStatusAC(false));


    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}

/** скачивание программ
 * @description {}
 * @param {number} orderId -
 * @param {string | null} email - отправить копию на email
 */

export const orderGenerateXncToQueueThunk = (orderId, email) => async dispatch => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        let data = {id: orderId};
        if (email) {
            data['email'] = email;
        }
        let form_data = createFormData(data)
        const response = await CommonOrderAPI.orderGenerateXncToQueue(form_data);
        if (!!response.error) {
            dispatch(dispatcherErrorThunk(response))
            return false
        }
        toast.success("OK");
        window.location.reload();
        // dispatch(setOrderDetailDataThunk(orderId))
        dispatch(toggleLoaderStatusAC(false));
        dispatch(toggleModalProjectGenerateConfirmAC({isOpen: false, orderId: null}))


    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}