import {useEffect, useState} from "react";
import {SearchMaterialAPI} from "../api/SearchMaterial/SearchMaterialAPI";
import {LS_FIRM} from "../utils/constants";

function useGetMaterialById({firm = null, goods_id = null}) {
    let firm_id = firm ? firm : localStorage.getItem(LS_FIRM)
    const [data, setData] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!goods_id) return null
        const fetchData = async () => {
            setIsPending(true); // Устанавливаем флаг загрузки
            setError(null); // Сбрасываем предыдущую ошибку

            try {
                const response = await SearchMaterialAPI.getById({firm: firm_id, goods_id: goods_id});
                if (response?.hasOwnProperty('error') || !!response.error) {
                    let msg = response.error
                    throw new Error(msg);
                }


                setData(response?.data?.data?.[0]);
            } catch (err) {
                let msg = err.hasOwnProperty('error') ? err.error : err.message;
                setError(msg);
            } finally {
                setIsPending(false); // Отключаем флаг загрузки после завершения
            }
        };

        fetchData();
    }, [goods_id]);

    return {data, isPending, error};
}

export default useGetMaterialById