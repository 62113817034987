import getT from "../getT";
import {unpacking_parts} from "./unpacking_parts";
import unpacking_material_table from "./unpacking_material_table";
import unpacking_band_table from "./unpacking_band_table";

export default function ({order, order_reset_data}) {
    let material = Array.isArray(order?.material) ? order?.material : []
    let band = Array.isArray(order?.band) ? order?.band : [];
    let count_part = Array.isArray(order?.count_part) ? order?.count_part : [];
    let part_entry_info = Array.isArray(order_reset_data?.parts) ? order_reset_data?.parts : [];
    let material_filtered = material?.filter(material => part_entry_info?.filter(part => Number(part.info.material) === Number(material.id))?.length)
    let pack_information = Array.isArray(order?.pack_information) ? order?.pack_information : [];

    let render = material_filtered?.map((material, index) => {
        let parts_include = part_entry_info
            ?.filter(part => {
                let product = !Array.isArray(part.info?.product) && part.info.product;
                let part_count = count_part.find(e => Number(e.part_id) === Number(part.info.id))?.count ?? 0
                let amountCountInPart = product ? (+product?.count * +part_count) : part_count;

                let partItemId = Number(part.info.id)
                let isMaterialCurrent = Number(part.info.material) === Number(material.id);
                let packInfoById = pack_information.find(partPack => Number(partPack.part_id) === partItemId);
                if (!packInfoById) {
                    return isMaterialCurrent;
                }

                let isNotAllMarkInPart = packInfoById?.marks?.length !== amountCountInPart;
                return isNotAllMarkInPart && isMaterialCurrent;
            })
            ?.map(e => {
                let part = e.info;
                let packInfoById = pack_information.find(partPack => Number(partPack.part_id) === part.id);
                let countMarkInPart = Array.isArray(packInfoById?.marks) ? packInfoById?.marks?.length : 0;
                let part_count = count_part.find(e => Number(e.part_id) === Number(part.id))

                return {...part, count: part_count?.count, link: e.link, qrCode: e?.qrCode, count_marks: countMarkInPart,}
            })
        let bands_include = band
            ?.filter(band => parts_include.some(part => Object.entries(part.edge).some(([_, values]) => Number(band.id) === Number(values))))
            ?.map(band => {
                return {
                    ...band, band_count: 0
                }
            })

        let material_list = [material]

        if (!material_list) return `
        <p>${getT("Не удалось создать")}</p>
        `

        return `
        <div style="font-size: 0; ">
            ${unpacking_material_table({material, index, material_list})}
            ${bands_include?.length ? unpacking_band_table({bands: bands_include}) : ''}
            ${parts_include?.length ? unpacking_parts({
            bands: bands_include,
            parts: parts_include
        }) : `<h5 class="text-info">${getT("Детали упакованы")}</h5>`}
         </div>   
   
        `
    })?.join('');

    return render;
}