import getT from "../getT";
import {LS_LANG} from "../../constants";

export default function ({material, index, material_list, full}){
    const total_counts = material_list?.reduce((acc, item)=>Number(item?.count)+ Number(acc), 0);
    let lang = localStorage.getItem('lang');
    let name = material?.translate?.[lang] ?? material?.name;
    return `
    <table>
     <caption>${getT("Материал")}: [${material?.id}] ${name}</caption>
  <thead>
    <tr>
      <th>#</th>
      <th>${getT("Длина")}</th>
      <th>${getT("Ширина")}</th>
     ${full ? `<th>${getT("Кол-во")}</th> `: null}
    </tr>
  </thead>
  <tbody>
  ${material_list?.map((item, indx)=>{
        return `
       <tr>
      <td>${indx+1}</td>
      <td>${item?.x}</td>
      <td>${item?.y}</td>
      ${full ?`<td>${item?.count}</td> `: null }
    </tr>
      `
    })?.join('')}
  ${full ? `<tr>
  <th colspan="3" class="b-bottom-hide b-left-hide"></th>
  <th colspan="1">${total_counts}</th>
  </tr>` : null}
  </tbody>
</table>
    `
}