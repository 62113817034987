import short_order_view from "./SalaryShortOrderForm/short_order_view";
import packing_view from "./SalaryShortOrderForm/packing_view";
import ready_service_view from "./SalaryShortOrderForm/ready_service_view";
import footer from "./SalaryShortOrderForm/footer";
import header from "./SalaryShortOrderForm/header";

/**
 * @method {generateContentHtml} -
 // *  @var settingViewPacking  {boolean}  - отобразить упакованные детали
 // *  @var settingViewUnpacked  {boolean}  - отобразить не упакованные детали
 */

export class SalaryShortOrderHtml {
    constructor() {
    }

    generateContentHtml({order, logo, order_parts = null}) {
        return `
       <div class="container">
       ${header({
            order_id: order.order,
            order_production_blank: order_parts.order_production_blank,
            logo: logo,
            qr: order_parts?.qr,
            title: order_parts?.title,
            date: order_parts?.date,
            client_name: order_parts?.client_name
        })}
     ${short_order_view({order, order_reset_data: order_parts})}
    ${packing_view({order_mark: order})}    
    ${ready_service_view({order_mark: order})}     
    ${footer({order, date: order_parts?.date})}

   </div>
    `
    }
}