import getT from "../getT";

export default function (order) {
    const {id, title, client_name, date} = order;
    return `
    <div class="form-qr-container">
    <table class="table table-bordered table-sm table-warning">
    <tr>
    <td>
                <strong>${getT("Заказ")} №</strong> ${id} "${title}" <br/>
    
</td>
    <td>${getT("Отчёт по пазам")}</td>
</tr>
<tr>
<td>
               <strong>${getT("Клиент")}:  </strong>${client_name}

</td>
<td></td>
</tr>
</table>
    </div>
<style>
.form-qr-container {
  
    max-width: 1200px;
    margin: 0 auto 25px auto;
}

.form-qr-container strong {
    color: #605d81;
   font-weight: 400;
}

</style>
  `
}