import getT from "../getT";

export const unpacking_parts = ({bands, parts}) => {
    if (!parts?.length) {
        return `<h4>${getT("Детали упакованны")}</h4>`
    }
    const total_counts = parts?.reduce((acc, item) => Number(item?.count || 0) + Number(acc), 0);


    return `
    <table>
      <caption>${getT("Не упакованные детали")}:   </caption>
        <thead>
    <tr>
      <th rowspan="2">#</th>
      <th rowspan="1" colspan="2">${getT("Пильный размер")}</th>
      <th  rowspan="2">${getT("Кол-во")}</th>
      <th  rowspan="2">${getT("Верх")}</th>
      <th  rowspan="2">${getT("Низ")}</th>
      <th  rowspan="2">${getT("Лево")}</th>
      <th  rowspan="2">${getT("Право")}</th>
      <th  rowspan="2">${getT("Текстура")}</th>
      <th  rowspan="2">${getT("Тип кромкования")}</th>
<!--      <th  rowspan="2">${getT("Описание")}</th>-->
      <th  rowspan="2">${getT("QR Code")}</th>
    </tr>
    <tr>
    <th>${getT("Длина")}</th>
    <th>${getT("Ширина")}</th>
 
</tr>
  </thead>
        <tbody>
  ${parts?.map((part, index) => {
        let find_band = (band_id) => (item) => Number(item?.id) === Number(band_id);
        let band_t = bands?.findIndex(find_band(part.edge.t)) >= 0 ? bands?.findIndex(find_band(part.edge.t)) + 1 : '';
        let band_b = bands?.findIndex(find_band(part.edge.b)) >= 0 ? bands?.findIndex(find_band(part.edge.b)) + 1 : '';
        let band_l = bands?.findIndex(find_band(part.edge.l)) >= 0 ? bands?.findIndex(find_band(part.edge.l)) + 1 : '';
        let band_r = bands?.findIndex(find_band(part.edge.r)) >= 0 ? bands?.findIndex(find_band(part.edge.r)) + 1 : '';

        return `
            <tr>
                <td>${part?.id}</td>
                <td>${part?.x1}</td>
                <td>${part?.y1}</td>
                <td>  <span style="cursor:pointer" title=${getT("Упакованно")}>${part.count_marks}</span> / <span style="cursor:pointer"  title=${getT("Всего")}>${part?.count}</span> </td>
                <td>${band_t}</td>
                <td>${band_b}</td>
                <td>${band_l}</td>
                <td>${band_r}</td>
                <td>${!!Number(part?.texture) ? '+' : ''}</td>
                <td>${part?.edge_type_part}</td>
        
                 <td>
                    <a class="d-block" target="_blank" href="${part.link}">
                    <img src="${part?.qrCode}" width="80px" height="80px"/>
                    </a>
                </td>
            </tr>
        `
    })?.join('')}
  <tr>
    <th colspan="3" class="b-left-hide b-bottom-hide"></th>
    <th colspan="1">${getT("Всего")}</th>
    <th colspan="1"> ${total_counts}</th>
    <th colspan="8"  class="b-right-hide b-bottom-hide"></th>
</tr>
     </table>
    `

}