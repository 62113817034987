import React from "react";
import {connect} from "react-redux";
import Orders from "./Orders";
import {
  changeOrdersFilterFormValueAC,
  createNewOrderThunk,
  getMainSettingsProject,
  getManagersDataThunk,
  getOrdersFilteredDataThunk,
  onClearActualSearchTunck,
  setClientsDataThunk,
  setOrdersDataAC,
  setOrdersDataThunk,
  toggleOrderChangeStatusAC,
  updateOrderDataThunk,
  updateSettingsThunk
} from "../../store/reducers/MainReducer";

class OrdersContainer extends React.Component {
  componentDidMount() {

    this.props.setOrders({
      ...this.props.filterForm, status:'actual'
    });
    // this.props.setOrders({ limit: 1, status: "actual" });
    this.props.setClients();
    // this.props.setManagers();
    this.props.setNullFilter("status", "actual");
    this.props.updateSettingsAC();
    this.props.get1COrdersCheckEnabled();

  }
  componentWillUnmount(){
    // this.props.checkAuntificationUser();
    this.props.setNullOrders([]);
  }

  render() {
    return (
      <Orders
        orders={
          this.props.archive
            ? this.props.orders_archive
            : this.props.orders_actual
        }
        title={!this.props.archive ? "orders_actual" : "orders_archive"}
        changeFilterValue={this.props.changeFilterValue}
        toggleChangeStatus={this.props.toggleChangeStatus}
        updateOrder={this.props.updateOrder}
        form={this.props.filterForm}
        status={this.props.orderStatus}
        clients={this.props.clients}
        changedStatus={this.props.changedStatus}
        createOrder={this.props.createOrder}
        userRoles={this.props.user.role}
        managers={this.props.managers}
        orders_check_enable={this.props.orders_check_enable}
        settings_projects = {this.props.settings_project}
        clearData={this.props.clearData}
        lang={this.props.lang}
      />
    );
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
    updateSettingsAC: () => dispatch(updateSettingsThunk()),
    setOrders: (filters = null) => dispatch(setOrdersDataThunk(filters)),
    changeFilterValue: (input, value, filters) =>
      dispatch(getOrdersFilteredDataThunk(input, value, filters)),
    setClients: () => dispatch(setClientsDataThunk()),
    toggleChangeStatus: (id) => dispatch(toggleOrderChangeStatusAC(id)),
    setManagers: () => dispatch(getManagersDataThunk()),
    updateOrder: (order_id, data, status_update='actual') =>
      dispatch(updateOrderDataThunk(order_id, data, status_update)),
    createOrder: (client_id) => dispatch(createNewOrderThunk(client_id)),
    setNullFilter: (input, value) =>
      dispatch(changeOrdersFilterFormValueAC(input, value)),
    get1COrdersCheckEnabled : ()=>
        dispatch(getMainSettingsProject()),
    clearData : ()=> dispatch(onClearActualSearchTunck()),
    setNullOrders: (orders) => dispatch(setOrdersDataAC(orders)),
  };
};

let mapStateToProps = (state) => {
  return {
    orders_actual: state.main.orders.actual,
    // orders_archive: state.main.orders.archive,
    filterForm: state.main.ordersForm,
    orderStatus: state.main.orderStatus,
    managers: state.main.managers,
    clients: state.main.clients,
    changedStatus: state.main.ordersChangeStatus,
    user: state.auth.user,
    orders_check_enable: state.main.orders_check_enable,
    settings_project: state.main.settings_project,
    lang: state.translation.local,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersContainer);
