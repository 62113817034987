import footer from "./SalaryShortOrderForm/footer";
import header from "./SalaryShortOrderForm/header";
import unpacking_view from "./SalaryUnpackingDetailInOrder/unpacking_view";

/**
 * @method {SalaryUnpackingDetailInOrderHtml} -  отобразить не упакованные детали
 */

export class SalaryUnpackingDetailInOrderHtml {
    constructor() {}

    generateContentHtml({order, logo, order_parts = null}) {
        return `
       <div class="container">
       ${header({
            order_id: order.order,
            order_production_blank: order_parts.order_production_blank,
            logo: logo,
            qr: order_parts?.qr,
            title: order_parts?.title,
            date: order_parts?.date,
            client_name: order_parts?.client_name
        })}
     ${unpacking_view({order, order_reset_data: order_parts})}
    ${footer({order, date: order_parts?.date})}

   </div>
    `
    }
}