import reportItem from "./reportItem";
import getT from "../getT";

export default function (group_direction) {
    return Object.keys(group_direction).map(key => {
        let pazByWidthDepth = group_direction[key];
        let {pazInfo, ...groupDirectionByMaterial} = group_direction[key];
        return `
                <div class="mb-5 mt-5">
                <h5 class="text-black mb-3">
                ${getT("Паз")} (${getT("Ширина")} ${pazInfo?.w_paz}${getT("мм")}, ${getT("Глубина")} ${pazInfo.z}${getT("мм")}):</h5>
           
             ${reportItem(groupDirectionByMaterial)}
               
            </div>
        `
    }).join('<hr/>')
}