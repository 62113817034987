import React, {useMemo} from 'react';
import Modal from "../MyModal/Modal";
import {useDispatch, useSelector} from "react-redux";
import {
    getAdvancedGoodsServiceForOrderSelector,
    setGoodsServiceForOrderModalSelector
} from "../../../store/selectors/advanced-goods-service-selector";
import {setExistingGoodsServiceForOrderModalAC} from "../../../store/actions/AdvancedGoodsServiceActions";
import {useForm} from "react-hook-form";
import Select from "react-select";
import {getGoodsServiceOrderCountCalculateWithType} from "./util_calculate_goods_service";
import {setAdvancedGoodsServiceForOrderIdThunk} from "../../../store/thunks/advansed-goods-service-thunk";

const SetGoodsServiceForOrderModal = ({getT}) => {
    const dispatch = useDispatch();
    const modal = useSelector(setGoodsServiceForOrderModalSelector);
    let goods_service_for_order = useSelector(getAdvancedGoodsServiceForOrderSelector)

    if (!modal.isOpen) return null;
    const goodsServiceOptions = goods_service_for_order.all?.filter(item => {
        let isExistService = goods_service_for_order?.existing
            ?.some(e => Number(e?.goods_service_for_order_by_firm) === Number(item?.goods_service_for_order_by_firm_id)) || false;
        return !isExistService;

    })
    const onClose = () => {
        dispatch(setExistingGoodsServiceForOrderModalAC({isOpen: false, orderId: null}));
    }
    const handleSubmit = (data) => {
        let payload = {
            goods_service_for_order_by_firm: data.item.goods_service_for_order_by_firm_id,
            currency: data.item.currency,
            count: data.count,
            order: modal.orderId,
            price: data.item.price,
        }
        dispatch(setAdvancedGoodsServiceForOrderIdThunk([payload], modal.orderId))
    }
    return (
        <Modal title={getT("Добавить")} onClose={onClose} open={modal.isOpen}>
            <Form getT={getT} onSubmit={handleSubmit}
                  goods_services={goodsServiceOptions} part={modal.part} products={modal.products}/>
        </Modal>
    );
};


const Form = ({getT, onSubmit, goods_services, part, products}) => {
    let defaultValues = {
        currency: "",
        select: "",
        item: null,
        is_edit: false,
        count: null

    }
    const {register, handleSubmit, setValue, watch} = useForm({
        ...defaultValues,

    });
    let isEditWatch = watch("is_edit");
    let countWatch = watch("count");
    let selectWatch = watch("select");
    let itemWatch = watch("item");

    let totalService = useMemo(() => {
        if (!itemWatch) return null
        let currency = itemWatch?.currency_name;
        let price = Number(itemWatch?.price);
        let total = Number(price) * Number(countWatch);
        return `${total} ${currency}`
    }, [itemWatch, countWatch])
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">

                <div className="col-12 col-md-6 form-group text-left">
                    <label
                        className={""}>{getT("Услуга")}</label>
                    <Select
                        options={goods_services?.map(item => ({
                            label: `${item.name} (${item.price}/${item.currency_name})`,
                            value: item?.goods_service_for_order_by_firm_id
                        }))}
                        value={selectWatch}
                        name={`select`}
                        onChange={(selectedValues) => {
                            let find_select = goods_services?.find(item => Number(item?.goods_service_for_order_by_firm_id) === Number(selectedValues.value));
                            let result = getGoodsServiceOrderCountCalculateWithType(find_select, part, products);
                            let item = {
                                select: selectedValues,
                                item: find_select,
                                count: result.value,
                                is_edit: result.is_edit
                            }
                            setValue("select", item.select)
                            setValue("item", item.item)
                            setValue("count", item.count)
                            setValue("is_edit", item.is_edit)
                        }}
                        style={{height: '51px'}}
                    />
                </div>
                {!!(selectWatch?.value) && <>
                    <div className="col-12 col-md-6 form-group text-left">
                        <label className={""}>{getT("Кол-во")}</label>
                        <input type="number" className="form-control"
                               {...register("count")}
                               value={countWatch}
                               name={`count`}
                               disabled={!isEditWatch}
                        />
                    </div>
                    <div className="col-12 col-md-6 form-group text-left">
                        <h5 className={"text-success"}>{getT("Цена")}: {itemWatch.price} {itemWatch.currency_name}</h5>
                        <h5 className={"text-success"}>{getT("Стоимость")}: {totalService}</h5>
                    </div>
                    <div className="col-12">
                        <button type={"submit"} disabled={countWatch <= 0}
                                className={"btn btn-primary"}>{getT("Отправить")}</button>
                    </div>
                </>
                }
            </div>


        </form>
    )
}
export default SetGoodsServiceForOrderModal;