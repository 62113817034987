import materialItem from "./materialItem";
import pazItemBySide from "./pazItemBySide";

export default function (groupDirectionByMaterial){
    return Object.keys(groupDirectionByMaterial)
        .map(keyMaterial =>{
            let {material, ...groupPazBySideD1} = groupDirectionByMaterial[keyMaterial];
           let renderItem = Object.keys(groupPazBySideD1)
               ?.map(key=>{
                   let item = groupPazBySideD1[key];
                   return pazItemBySide(item);
               }).join(' ')
            return `
             ${materialItem(material)}
             <table class="table table-bordered table-striped">
                ${renderItem}
            </table>
            `

        })?.join('')
}